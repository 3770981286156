import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.conference || initialState;

  export const selectIsAllowedToJoinRoom = createSelector(
    [selectDomain],
    conferenceState => conferenceState.allowedJoinToRoom,
  );

  export const selectJoinedRoom = createSelector(
    [selectDomain],
    conferenceState => conferenceState.roomJoinedTo,
  );

  export const selectIsSidebarOpen = createSelector(
    [selectDomain],
    conferenceState => conferenceState.isSidebarOpen,
  );

  export const selectGuestRequests= createSelector(
    [selectDomain],
    conferenceState => conferenceState.guestRequests,
  );
  export const selectParticipantList= createSelector(
    [selectDomain],
    conferenceState => {
      return conferenceState.participants.filter(p => p.jitsiId && p.socketId && p.uuid)
    },
  );
  export const selectGuestRequestCount= createSelector(
    [selectDomain],
    conferenceState => conferenceState.guestRequests.length,
  );
  export const selectModeratorAudioVideoToggleParticipant= createSelector(
    [selectDomain],
    conferenceState => conferenceState.moderatorAudioVideoToggleParticipant,
  );
  export const selectToggleAudioVideo= createSelector(
    [selectDomain],
    conferenceState => conferenceState.toggleAudioVideo,
  );
  export const selectChangePresenter= createSelector(
    [selectDomain],
    conferenceState => conferenceState.changePresenter,
  );
  export const selectMuteRequest= createSelector(
    [selectDomain],
    conferenceState => conferenceState.requestToUnmute,
  );
  export const selectParticipants= createSelector(
    [selectDomain],
    conferenceState => conferenceState.participants,
  );
  export const selectRequestLowerAllHandByModerator= createSelector(
    [selectDomain],
    conferenceState => conferenceState.requestLowerAllHandByModerator,
  );
  export const selectRequestLowerHandByModerator= createSelector(
    [selectDomain],
    conferenceState => conferenceState.requestLowerHandByModerator,
  );
  export const selectRequestRemoveParticipant= createSelector(
    [selectDomain],
    conferenceState => conferenceState.requestRemoveParticipant,
  );
  export const selectRequestCloseMeeting= createSelector(
    [selectDomain],
    conferenceState => conferenceState.requestCloseMeeting,
  );
  export const selectIamToggleRaisedHand= createSelector(
    [selectDomain],
    conferenceState => conferenceState.iamToggleRaisedHand,
  );
  export const selectIsGuestTokenAvailable= createSelector(
    [selectDomain],
    conferenceState => conferenceState.isGuestTokenAvailable,
  );
  export const selectChangeDisplayName= createSelector(
    [selectDomain],
    conferenceState => conferenceState.changeDisplayName,
  );
  export const selectMuteAll= createSelector(
    [selectDomain],
    conferenceState => conferenceState.muteAll,
  );
  export const selectRepNotes= createSelector(
    [selectDomain],
    conferenceState => conferenceState.repNotes,
  );

