import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conferenceActions} from '../slice';
export function MuteAllButton() {
    
    const dispatch = useDispatch();
    const onMuteAll = () => {
        dispatch(conferenceActions.toggleMuteAll({muteAll:true}))
    }
    const onUnMuteAll = () => {
      dispatch(conferenceActions.toggleMuteAll({muteAll:false}))
  }
    return (
      <button className="secondary-button bottomButton" onClick={onMuteAll}>{'Mute All'}</button>
    );
  }