import React from 'react';
import {useDispatch} from 'react-redux';
import { conferenceActions } from '../slice';
import './index.scss';
export function SidebarHeader() {
  const dispatch = useDispatch();
  return (
    <div className="SIDEBAR_HEADER--">
      <div className="leftSide">
        <div>Participants</div>
        {/* <span>43</span> */}
        </div>
      <button className="rightClose" onClick={() => dispatch(conferenceActions.toggleSidebar())}>+</button>
    </div>
  );
}