export function AskUnmuteIcon () {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style={{ enableBackground:'new 0 0 512 512'}} className="fillPrimaryColor" >
        <g>
            <g xmlns="http://www.w3.org/2000/svg">
                <path d="m431 240c0-8.284-6.716-15-15-15s-15 6.716-15 15c0 79.953-65.047 145-145 145s-145-65.047-145-145c0-8.284-6.716-15-15-15s-15 6.716-15 15c0 91.442 70.501 166.716 160 174.35v67.65h-81c-8.284 0-15 6.716-15 15s6.716 15 15 15h192c8.284 0 15-6.716 15-15s-6.716-15-15-15h-81v-67.65c89.499-7.634 160-82.908 160-174.35z" data-original="#000000"/><path d="m312 255c-8.284 0-15-6.716-15-15s6.716-15 15-15h55v-34h-71c-8.284 0-15-6.716-15-15s6.716-15 15-15h71v-34h-55c-8.284 0-15-6.716-15-15s6.716-15 15-15h54.618c-4.541-54.252-49.724-97-104.618-97h-12c-54.894 0-100.078 42.749-104.619 97h54.619c8.284 0 15 6.716 15 15s-6.716 15-15 15h-55v34h71c8.284 0 15 6.716 15 15s-6.716 15-15 15h-71v34h55c8.284 0 15 6.716 15 15s-6.716 15-15 15h-54.61c4.581 53.7 49.745 96 104.61 96h12c54.865 0 100.029-42.3 104.61-96z" data-original="#000000" />
            </g>
        </g>
    </svg>
    )
}

export default AskUnmuteIcon;