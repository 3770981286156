export const API = {
    FORGOT_PASSWORD: {
      path: '/auth/forgot-password',
    //   method: 'PUT',
    }, 
    RESET_PASSWORD: {
      path: '/auth/reset-password',
    //   method: 'PUT',
    },  
    SHELL_LOGIN: {
        path: '/auth/meet/login',
        method: 'POST',
      },
    GET_MEET_TOKEN: {
      path: '/auth/meet-data',
      method: 'POST',
    }, 
    LOGIN_BY_TOKEN: {
      path: '/auth/login-by-token',
      method: 'GET',
    },  
};
