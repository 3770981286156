import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { MuteAllButton } from '../MuteAllButton';
import './index.scss';
import { selectSecuritySettings, selectCurrentUser } from '../../auth/selectors'
import { SECURITY_SETTINGS_FIELDS } from '../../auth/types';
import { authActions } from '../../auth/slice'
import { LowerAll } from '../LowerAll';
import { MeetingType, ROLE } from '../../app/types';
import {selectAppStatus, selectRoomData} from '../../app/selectors';


export function SidebarBottomButtons() {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const dispatch = useDispatch();
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const securitySettings = useSelector(selectSecuritySettings);
  const currentUser = useSelector(selectCurrentUser);
  const roomData = useSelector(selectRoomData);

  const updateSecuritySettings = (field: string, value: boolean) => {
    dispatch(authActions.updateSecuritySettings({ field, value }))
  }
  const isModerator = () => {

    return currentUser.roles.includes(ROLE.MODERATOR)
  }
 
  return (
    <div className="SIDEBAR_BOTTOM_BUTTONS--">
      {/* mute all button will change to unmute all */}
      {isModerator() && <MuteAllButton />}
      {isModerator() && <LowerAll />}
      {isModerator() && <button className="secondary-button bottomButton" id="sidebarMoreButton">More</button>}
      {isModerator() && <Popover placement="bottom" isOpen={popoverOpen} target="sidebarMoreButton" toggle={togglePopover} className="sidebarMore_popover" trigger="legacy">
        <PopoverBody>
          <label>
            <input
              type="checkbox"
              checked={securitySettings.allowParticipantToUnmuteThemSelf}
              onChange={() => updateSecuritySettings(SECURITY_SETTINGS_FIELDS.ALLOW_PARTICIPANT_TO_UNMUTE_THEM_SELF, !securitySettings.allowParticipantToUnmuteThemSelf)}
            />
            <div>Allow participants to unmute themselves</div>
          </label>
          <label>
            <input
              type="checkbox" 
              checked={!securitySettings.waitingRoomProtected}
              onChange={() => updateSecuritySettings(SECURITY_SETTINGS_FIELDS.WAITING_ROOM_PROTECTED, !securitySettings.waitingRoomProtected)}
            />
            <div>Disable waiting room</div>
          </label>
        </PopoverBody>
      </Popover>}
    </div>
  );
}