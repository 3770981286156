import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UrlParser } from 'url-params-parser';
import { getRoomData } from './slice';
import {selectAppStatus} from './selectors';
import { AppStatus } from './types';
import { conferenceActions } from '../conference/slice'
import './InitialLoader.scss';
import React from 'react';


export function InitialLoader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [room, setRoom] = useState('');

  const appStatus = useSelector(selectAppStatus);

  console.log(window.location.pathname);

  useEffect(() => {
    const parsedUrl = UrlParser(window.location.href, '/:room');
    if(parsedUrl.namedParams.room !== '/'){
      setRoom(parsedUrl.namedParams.room);
    } else {
      history.push('/home')
    }
    
  }, []);

  useEffect(() => {
    const parsedUrl = UrlParser(window.location.href);
    const url= window.location.href
    console.log('this is pathname>>>',parsedUrl);
    console.log('this is url>>>',url);
    // console.log('this is pathname>>>',parsedUrl);
    if(parsedUrl.pathname === '/set-new-password'){
      history.push('/set-new-password')
    } 
    
  }, []);

  useEffect(() => {
    if (room) {
      dispatch(getRoomData(room));
    }
  }, [dispatch, room]);

  useEffect(() => {
    if (appStatus === AppStatus.READY_FOR_CONNECT) {
      dispatch(conferenceActions.joinAttempt(room));
    } else if (appStatus === AppStatus.NOT_STARTED) {
      history.push('/waiting-for-host')
    } else if (appStatus === AppStatus.STARTED) {
      history.push('/display-name')
    }
    console.log('appStatus',appStatus)
  }, [appStatus, room]);

  // const history = useHistory();

  // useEffect(() => {
  //   history.push(window.location.pathname);
  // }, [history]);

  return (
    <div className="INITIAL_LOADER--">
      <div className="loadingio-spinner-spinner-xnkwrjd3ve"><div className="ldio-d4mber2npuo">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
      {/* <h1>Initial Loader</h1>
      <Link to="/login">Login</Link>
      <br />
      <Link to="/waiting-view">Waiting View</Link>
      <br />
      <Link to="/waiting-for-host">Waiting for host</Link>
      <br />
      <Link to="/display-name">Display Name Input</Link>
      <br />
      <Link to="/conference">Conference</Link>
      <br /> */}
    </div>
  );
}
