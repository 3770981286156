import { createAction } from '@reduxjs/toolkit';

const createConferenceAction = <T>(actionType: string) => createAction<T>(`conference/${actionType}`);

export const hangupByModerator = createConferenceAction('hangupByModerator');
export const localUserHangedUp = createConferenceAction('localUserHangedUp');
export const joinedToConference = createConferenceAction<string>('joinedToConference');
export const joinAsModerator = createConferenceAction<void>('joinAsModerator');
export const joinRequestRejected = createConferenceAction<void>('joinRequestRejected');
export const renameParticipant = createConferenceAction<{ uuid: string, newName: string }>('renameParticipant');
export const renameParticipantByModerator = createConferenceAction<{ uuid: string, newName: string }>('renameParticipantByModerator');


