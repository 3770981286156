import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { conferenceActions} from '../slice';
export function LowerAll() {
    
    const dispatch = useDispatch();
    const lowerAllRaisedHand = () => {
      dispatch(conferenceActions.lowerAllHand({}))
  }
    return (
        <button className="secondary-button bottomButton" onClick={lowerAllRaisedHand}>Lower all</button>
    );
  }