import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import './index.scss';

import hand from 'images/conference/hand.svg';
import handLight from 'images/conference/hand-light.svg';
import micOff from 'images/conference/mic-off.svg';
import mic from 'images/conference/mic.svg';
import videoCamOff from 'images/conference/videocam-off.svg';
import videoCam from 'images/conference/videocam.svg';
import user from 'images/conference/user.svg';
import { Participant } from './../types'
import { selectCurrentUser,selectCurrentUserRoles } from '../../auth/selectors';
import { conferenceActions } from '../slice';
import { ROLE } from '../../app/types';
import { selectSecuritySettings } from '../../auth/selectors'
import { ParticipantOptions } from '../ParticipantOptions';
import { renameParticipant, renameParticipantByModerator } from '../actions';
import { authActions } from '../../auth/slice';


interface Props {
  participant: Participant;
}
export function EachParticipant(props: Props) {

  const dispatch = useDispatch();
  const {
    participant: {
      name,
      socketId,
      uuid,
      roles,
      jitsiId,
      status: {
        audioMuted,
        videoMuted,
        handRaised,

      }
    }
  } = props;
  const currentUser = useSelector(selectCurrentUser);
  const currentUserRoles = useSelector(selectCurrentUserRoles);
  const [handStatus, setHandStatus] = useState(handRaised);


  const securitySettings = useSelector(selectSecuritySettings);
  const [me, setMe] = useState<boolean>(false);
  const [showNameInput, setShowNameInput] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');

  useEffect(() => {
    if (uuid === currentUser.uuid) {
      setMe(true)
    }
  }, [currentUser, uuid])

  const isModerator = () => {

    return currentUser.roles.includes(ROLE.MODERATOR)
  }
  const isHost = () => {
    return roles.includes(ROLE.MODERATOR)
  }
  const moderatorAudioToggleOnParticipant = () => {
    if (!me) {
      dispatch(conferenceActions.moderatorRequestAudioVideoToggleParticipant({ uuid, mute: !audioMuted, type: 'audio' }))
    } else {
      dispatch(conferenceActions.audioVideoToggle({ uuid, mute: !audioMuted, type: 'audio' }))

    }


  }
  const moderatorVideoToggleOnParticipant = () => {
    if (!me) {
      dispatch(conferenceActions.moderatorRequestAudioVideoToggleParticipant({ uuid, mute: !videoMuted, type: 'video' }))
    } else {
      dispatch(conferenceActions.audioVideoToggle({ uuid, mute: !videoMuted, type: 'video' }))
    }


  }

  const isAllowToUnmuteAudio = () => {
    if ((isModerator() || isPresenter()) && (me|| !audioMuted)) {
      return true;
    } 
    else if (me) {
      if(!audioMuted){
        return true
      }
      return securitySettings.allowParticipantToUnmuteThemSelf 
    }
    return false;
  }
  const isAllowToUnmuteVideo = () => {
    if ((isModerator() || isPresenter()) && (me|| !videoMuted)) {
      return true;
    }
    else if (me) {
      if(!videoMuted){
        return true
      }
      return securitySettings.allowParticipantToUnmuteThemSelf
    }
    return false;
  }
  const isPresenter = () => {
    return roles.includes(ROLE.PRESENTER)
  }
  // const iamToggleRaisedHand = () =>{
  //   dispatch(conferenceActions.iamToggleRaisedHand({jitsiId}))
  // }
  const toggledRaisedHand = () => {
    if (isModerator() && !me) {
      dispatch(conferenceActions.lowerHandByModerator({ jitsiId, uuid }))
    } else {
      dispatch(conferenceActions.iamToggleRaisedHand({ jitsiId }))
    }
  }
  const isAllowToToggleRaisedHand = () => {
    if (isModerator() || me) {
      return true
    }
    return false;
  }

  const handleNameInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if(!me){
        dispatch(renameParticipantByModerator({ uuid: props.participant.uuid, newName }));
      }else{
        dispatch(authActions.setCurrentUserName(newName));
        dispatch(conferenceActions.changeDisplayName({uuid: props.participant.uuid, newName: newName}))
      }
      
      setNewName('');
      setShowNameInput(false);
    }
  }

  const handleNameInputKeyPressAside = () => {
  
    if(!me){
      dispatch(renameParticipantByModerator({ uuid: props.participant.uuid, newName }));
    }else{
      dispatch(authActions.setCurrentUserName(newName));
      dispatch(conferenceActions.changeDisplayName({uuid: props.participant.uuid, newName: newName}))
    }
    
    setNewName('');
    setShowNameInput(false);
    
  }

  const setShowNameInputAsModerator = () => {
    if(isModerator()){
      setShowNameInput(true);
    }
  }

  return (
    <div className="EACH_PARTICIPANT--">
      <div className="leftHandSide">
        <div className="photoContainer">
          <img className="profile" src={user} alt="" />
          <span />
        </div>
        <div className="nameDetails">
          {
            showNameInput ? <input onBlur={handleNameInputKeyPressAside} onKeyPress={handleNameInputKeyPress} value={newName} onChange={e => setNewName(e.target.value)} type="text" /> : <div onClick={!me? (() => setShowNameInputAsModerator()): (() => setShowNameInput(true))}>{name}{me ? '(me)' : ''}{isPresenter() ? '(Presenter)' : ''}</div>
          }
          <div className="title">{isHost()?'Host': 'Participant'}</div>
        </div>
      </div>
      <div className="rightHandSide">
        {handRaised && <button className={handStatus ? 'secondary-button darken' : 'secondary-button'} disabled={!isAllowToToggleRaisedHand()} onClick={toggledRaisedHand}>
          <img src={handStatus ? handLight : hand} alt="" />
        </button>}
        {
          currentUserRoles.filter(role=>role==="moderator").length>0 &&<>
          <button className="secondary-button admitButton" onClick={() => moderatorAudioToggleOnParticipant()} disabled={!isAllowToUnmuteAudio()}>
            <img src={audioMuted ? micOff : mic} alt="" />
          </button>
          <button className="secondary-button admitButton" onClick={() => moderatorVideoToggleOnParticipant()} disabled={!isAllowToUnmuteVideo()}>
            <img src={videoMuted ? videoCamOff : videoCam} alt="" />
          </button>
        </>
        }
        <ParticipantOptions
          uuid={uuid}
          jitsiId={jitsiId}
          roles={roles}
          audioMuted={audioMuted}
          videoMuted={videoMuted}
          onRenameClick={() => setShowNameInput(true)}
        />
      </div>
    </div>
  );
}