import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { appReducer, appSliceKey } from '../features/app/slice';
import { conferenceReducer, conferenceSliceKey } from '../features/conference/slice';
import { authReducer, authSliceKey } from '../features/auth/slice';
import { socketMiddleware } from '../features/socket/socketMiddleware';
import { AppState } from '../features/app/types';
import { ConferenceState } from '../features/conference/types';
import { AuthState } from '../features/auth/types';
import { NotificationState } from '../features/notification/types';
import { notificationReducer, notificationSliceKey } from '../features/notification/slice';
import logger from 'redux-logger'

export const store = configureStore({
  reducer: {
    [appSliceKey]: appReducer,
    [conferenceSliceKey]: conferenceReducer,
    [authSliceKey]: authReducer,
    [notificationSliceKey]: notificationReducer,
  },
  middleware: (getDefaultMiddleware) => (
    [...getDefaultMiddleware(), socketMiddleware,logger]
  )
});

export type RootState = {
  app: AppState,
  conference: ConferenceState,
  auth: AuthState,
  notification: NotificationState,
};
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;