import {Header} from '../../header';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import meetingPic from 'images/premeeting/casual-meeting.png';
import './index.scss';
import { selectIsAllowedToJoinRoom } from '../../conference/selectors';
import { selectRoomData } from '../../app/selectors';
import React, { useEffect } from 'react';
import {selectAppStatus} from '../../app/selectors';
import { AdCarousel } from '../AdCarousel'
import { AppStatus, MeetingType } from '../../app/types';
import vvalogo from 'images/branding/logo.png';


export function WaitingView() {
  const history = useHistory();
  const isAllowedToJoinRoom = useSelector(selectIsAllowedToJoinRoom);
  const roomData = useSelector(selectRoomData);
  const appStatus = useSelector(selectAppStatus);

  useEffect(() => {
    if (isAllowedToJoinRoom) {
      history.push('/conference');
    }
  }, [history, isAllowedToJoinRoom]);

  useEffect(() => {
    if (appStatus === AppStatus.JOIN_REQUEST_REJECTED) {
      history.push('/join-request-rejected')
    }
  }, [appStatus, history]);
  const isScheduleMeet = () => {
    if(roomData?.meetingType === MeetingType.DOCTOR_REPRESENTATIVE_MEETING){
      return true;
    }
    return false;
  }
  return (
    <div className="WAITING_VIEW--">
      <Header comingFrom="WAITING_FOR_ADMISSION"/>
      <div className="content">
        <div className="topHalf">
          <div className="carouselWrapper">
            <AdCarousel />
          </div>
          {/* <img src={`${RESOURCES_BUCKET_URL}/${roomData?.drugImageKey}`} alt="" /> */}
          <h1>Welcome to the meeting {isScheduleMeet() && <span>, Dr. {roomData?.doctorName}</span>}</h1>
          <h2>Please wait while the representative admits you to the meeting</h2>
        </div>
        <div className="bottomHalf">
          {/* <div className="isHost">
            <div>Or, if you are the presenter</div><Link to="/login">Sign in</Link>
          </div> */}
          <div className="poweredBy">
            <div>Powered by</div>
            <img src={vvalogo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}