import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import { MEET_URL, DOMAIN } from '../../../configs';
import { 
  selectJoinedRoom, 
  selectModeratorAudioVideoToggleParticipant, 
  selectToggleAudioVideo, 
  selectChangePresenter,
  selectMuteRequest,
  selectRequestLowerAllHandByModerator,
  selectRequestRemoveParticipant,
  selectRequestCloseMeeting,
  selectIamToggleRaisedHand,
  selectRequestLowerHandByModerator,
  selectIsGuestTokenAvailable,
  selectChangeDisplayName,
  selectMuteAll,
  selectIsAllowedToJoinRoom
 } from "../selectors";
import { conferenceActions, guestLogin } from '../slice';
import { joinedToConference } from '../actions';
import { selectCurrentUser, selectSecuritySettings, selectCurrentUserStatus,selectPreferedAudioId,selectPreferedVideoId,selectMuteParticipantsUponEntry } from '../../auth/selectors';
import { authActions } from '../../auth/slice';
import { notificationActions } from '../../notification/slice'
import { NOTIFICATION_TYPES } from '../../notification/constants'
import { ROLE } from 'features/app/types';
import {HangUpModal} from '../Hangup';
import { renameParticipant } from '../actions';


let api: any = null;
export function MeetView() {

  const history = useHistory();

  const joinedRoom = useSelector(selectJoinedRoom);
  const currentUser = useSelector(selectCurrentUser);
  const currentUserStatus = useSelector(selectCurrentUserStatus);
  const preferedAudioId = useSelector(selectPreferedAudioId);
  const preferedVideoId = useSelector(selectPreferedVideoId);
  const moderatorAudioVideoToggleParticipant = useSelector(selectModeratorAudioVideoToggleParticipant)
  const toggleAudioVideo = useSelector(selectToggleAudioVideo);
  const changePresenter = useSelector(selectChangePresenter);
  const muteRequest = useSelector(selectMuteRequest)
  const requestLowerAllHandByModerator = useSelector(selectRequestLowerAllHandByModerator)
  const securitySettings = useSelector(selectSecuritySettings);
  const requestRemoveParticipant = useSelector(selectRequestRemoveParticipant)
  const requestCloseMeeting = useSelector(selectRequestCloseMeeting)
  const iamToggleRaisedHand = useSelector(selectIamToggleRaisedHand);
  const requestLowerHandByModerator = useSelector(selectRequestLowerHandByModerator);
  const isGuestTokenAvailable = useSelector(selectIsGuestTokenAvailable);
  const changeDisplayName = useSelector(selectChangeDisplayName);
  const muteParticipantsUponEntry = useSelector(selectMuteParticipantsUponEntry);
  const allowedToJoinRoom = useSelector(selectIsAllowedToJoinRoom);
  const muteAll = useSelector(selectMuteAll)

  const [hangUpModal, setHangUpModal] = useState(false);


  const dispatch = useDispatch();

  const getToken = () => {
    return sessionStorage.getItem(`${DOMAIN}-meet-token`)
  }
  const initiateConference = () => {
    const domain = MEET_URL;
    const options = {

      roomName: joinedRoom,
      jwt:getToken(),
      userInfo: {
        displayName: currentUser.name
      },
      configOverwrite: { 
        startWithAudioMuted:muteParticipantsUponEntry || currentUserStatus.audioMuted, 
        startWithVideoMuted:currentUserStatus.videoMuted, 
        prejoinPageEnabled:false },
      interfaceConfigOverwrite:{ SHOW_JITSI_WATERMARK: false,},
      width: '100%',
      height: '100%',
      parentNode: document.querySelector('#meet'),
      devices:{
        audioInput:preferedAudioId,
        videoInput:preferedVideoId
      }
    };
    api = new JitsiMeetExternalAPI(domain, options);

    api.addEventListener('videoConferenceJoined', onJoinedConference);
    api.addEventListener('participantJoined',participantJoined)
    api.addEventListener('audioMuteStatusChanged',audioMuteStatusChanged)
    api.addEventListener('videoMuteStatusChanged',videoMuteStatusChanged)
    api.addEventListener('raiseHandUpdated',raiseHandUpdated)
    api.addEventListener('customHangUp',customHangUp)
    api.addEventListener('participantLeft',participantLeft)
  }
  const participantJoined = ({id,displayName}:{id:string,displayName:string}) => {
    dispatch(conferenceActions.participantJoined({id,name:displayName}))
  }
  const audioMuteStatusChanged = ({muted}:{muted:boolean}) =>{
    dispatch(conferenceActions.audioVideoMuteStatusChanged({muted, type:'audio'}))
  }
  const videoMuteStatusChanged = ({muted}:{muted:boolean}) =>{
    // if (currentUserStatus.videoMuted==muted){
      dispatch(conferenceActions.audioVideoMuteStatusChanged({muted, type:'video'}))
    // }
    
  }
 
  const onJoinedConference = ({ id }: { id: string }) => {
    console.log('onJoinedConference',id)
    dispatch(joinedToConference(id));
    api.executeCommand('displayName', currentUser.name);
  }
  const raiseHandUpdated = ({id,handRaised}:{id: string,handRaised: boolean}) => {
    dispatch(conferenceActions.raiseHandUpdated({id,handRaised}));
  }
  const customHangUp = () =>{
    setHangUpModal(true);
  }
  const participantLeft = ({id}:{id:string}) => {
    dispatch(conferenceActions.participantLeft({id}));
  }
  const isModerator = () => {

    return currentUser.roles.includes(ROLE.MODERATOR)
  }
  const canIHang = () => {
    const anyRoles = [ROLE.MODERATOR, ROLE.PRESENTER].filter(r=> currentUser.roles.includes(r))
    return anyRoles.length>0
  }
  useEffect(() => {
    if(isModerator()){
      initiateConference();
    } else{
      dispatch(guestLogin({name:currentUser.name, room: joinedRoom }))
    }
   
    return () => {
      if (api) {
        api.dispose()
      }
    }
  }, [])

  useEffect(()=>{
    if(api && moderatorAudioVideoToggleParticipant.type  ){
      if(moderatorAudioVideoToggleParticipant.type === 'audio'){
        api.executeCommand('toggleAudio');
      } else if(moderatorAudioVideoToggleParticipant.type === 'video'){
        api.executeCommand('toggleVideo');
      }
     
    }
  },[api, moderatorAudioVideoToggleParticipant])
  useEffect(()=>{
    if(api && toggleAudioVideo.type ){
      if(toggleAudioVideo.type === 'audio'){
        api.executeCommand('toggleAudio');
      } else if(toggleAudioVideo.type === 'video'){
        api.executeCommand('toggleVideo');
      }
    }
  },[api, toggleAudioVideo])
  useEffect(()=>{
    if(changePresenter.jitsiId){
      api.executeCommand('makePresenter',{id:changePresenter.jitsiId, enabled: changePresenter.enable});
      api.executeCommand('setLargeVideoParticipant', changePresenter.jitsiId);
    }
  },[changePresenter])
  useEffect(()=>{
    if(api && muteAll > 0 && !currentUser.status.audioMuted){
      api.executeCommand('toggleAudio');
    }
  },[muteAll,api])

  useEffect(()=>{
    if(muteRequest.uuid){
        if(muteRequest.by === ROLE.GUEST){
          dispatch(notificationActions.askUnMuteNotification({
            message:`${muteRequest.name} is asking to ${muteRequest.type === 'audio' ? 'speak' : 'camera on'} `,
            name: 'Unmute',
            notificationType:NOTIFICATION_TYPES.ASK_UNMUTE_EVENT
          }))
        } else {
          dispatch(notificationActions.askUnMuteNotification({
          message:`The host is requesting you to unmute ${muteRequest.type === 'audio' ? 'Audio' : 'Video'}  yourself`,
          name: 'Unmute',
          notificationType:NOTIFICATION_TYPES.ASK_UNMUTE_EVENT
        }))
        }
        
    }
  },[muteRequest])

  useEffect(()=>{
    if(requestLowerAllHandByModerator>0 && currentUser.status.handRaised && api){
      api.executeCommand('toggleRaiseHand', false);
    }
  },[requestLowerAllHandByModerator,api])

  useEffect(()=>{
    if(api){
      api.executeCommand('enableParticipantUnmuteThemSelf', securitySettings.allowParticipantToUnmuteThemSelf);
    }
   
  },[securitySettings.allowParticipantToUnmuteThemSelf, api])
  const thankYouPage = () => {
    console.log("Thank you is firing >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    history.push('/thank-you')
  }
  useEffect(()=>{
    if(requestRemoveParticipant && api && allowedToJoinRoom){
      console.log("I am firing >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
      api.executeCommand('hangup');
      thankYouPage()
    }
  },[requestRemoveParticipant, api, allowedToJoinRoom])
  useEffect(()=>{
    if(requestRemoveParticipant && api && !allowedToJoinRoom){
      api.executeCommand('hangup');
      history.push('/waiting-view');
    }
  },[requestRemoveParticipant, api,allowedToJoinRoom])

  useEffect(()=>{
    if(requestCloseMeeting && api && allowedToJoinRoom){
      api.executeCommand('hangup');
      thankYouPage()

    }
  },[requestCloseMeeting,api,allowedToJoinRoom])

  useEffect(()=>{
    if(iamToggleRaisedHand > 0 && api){
      api.executeCommand('toggleRaiseHand', false);
    }
    
  },[iamToggleRaisedHand, api])
  useEffect(()=>{
    if(api && requestLowerHandByModerator){
      api.executeCommand('toggleRaiseHand', false);
    }
  },[requestLowerHandByModerator,api])
  useEffect(()=>{
    if(isGuestTokenAvailable){
      initiateConference()
    } else {
      
    }
  },[isGuestTokenAvailable])
  useEffect(()=>{
    if(api && changeDisplayName.newName){
        api.executeCommand('displayName', changeDisplayName.newName);
        dispatch(authActions.setCurrentUserName(changeDisplayName.newName))
        dispatch(renameParticipant({uuid: changeDisplayName.uuid, newName: changeDisplayName.newName}));
    }
  },[api, changeDisplayName])
  return (
    <>
      <div id="meet"></div>
      <HangUpModal hangUpModal={hangUpModal} setHangUpModal={setHangUpModal} api={api} moderator={isModerator()}/>
    </>
  );
}