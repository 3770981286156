import { SidebarHeader } from '../SidebarHeader';
import { ParticipantsList } from '../ParticipantList'
import { WaitingList } from '../WaitingList';
import { SidebarBottomButtons } from '../SidebarBottomButtons';
import './index.scss';
import { selectCurrentUser } from '../../auth/selectors';
import { selectGuestRequests } from '../selectors';
import { useSelector } from 'react-redux';
import React from 'react';

export function Sidebar() {
  const currentUser = useSelector(selectCurrentUser);
  const guestRequests = useSelector(selectGuestRequests);

  return (
    <div className="SIDEBAR--">
      <SidebarHeader />
      <div className="allParticipantsSection">
        {
          currentUser.roles.includes('moderator') && guestRequests.length > 0 && <WaitingList />
        }
        <ParticipantsList />
      </div>
      <div className="bottomButtonsSection">
        <SidebarBottomButtons />
      </div>
    </div>
  );
}