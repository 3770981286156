import { socket } from "./socketConnection";
import { store } from "../../store/store";
import { conferenceActions } from "../conference/slice";
import { notificationActions } from "../notification/slice";
import {
  hangupByModerator,
  localUserHangedUp,
  joinedToConference,
  joinRequestRejected
} from "../conference/actions";
import {
  joinAsGuestRequest,
  meetingStartedAlready,
  waitForModerator,
} from "../app/actions";
import { GuestRequest } from "../conference/types";
import { User } from '../app/types';
import { ISecuritySettings } from '../auth/types';
import { authActions } from '../auth/slice';
import { DOMAIN } from '../../configs';
import { NOTIFICATION_TYPES } from "features/notification/constants";

socket.on("connect", () => {
  console.log("Connected to the socket server..");
  console.log(socket.id);
});

socket.on("disconnect", () => {
  console.info("Disconnected from socket server...");
});

socket.on("waiting-for-moderator", () => {
  store.dispatch({ type: waitForModerator.type });
});

socket.on("meeting-started-already", () => {
  store.dispatch({ type: meetingStartedAlready.type });
});

socket.on("joined-as-moderator", ({securitySettings}:{securitySettings:ISecuritySettings}) => {
  store.dispatch({
    type: conferenceActions.joinedAsModerator.type,
    payload: {
      roomWaitingFor: store.getState().conference.roomWaitingFor,
      securitySettings:securitySettings
    },

  });
});

socket.on("joined-as-guest", ({securitySettings, meetToken}:{securitySettings:ISecuritySettings, meetToken:string}) => {
  sessionStorage.setItem(`${DOMAIN}-meet-token`, meetToken)
  const room = store.getState().conference.roomWaitingFor;
  store.dispatch({
    type: conferenceActions.joinedAsGuest.type,
    payload: {
      room,
      securitySettings:securitySettings
    },
  });
});

socket.on("join-guest-request-to-moderator", ({ user } : any) => {
  store.dispatch({
    type: conferenceActions.joinAsGuestRequestToModerator.type,
    payload: user,
  });
  store.dispatch({
    type: notificationActions.guestJoiningToWaitingRoom.type,
    payload: {
      message: `${user.name} is in waiting room`,
      notificationType: NOTIFICATION_TYPES.DEFAULT
    },
  });
});

socket.on("join-request-rejected", () => {
  store.dispatch({
    type: joinRequestRejected.type
  });
});

socket.on(
  "waiting-guest-disconnected",
  ({ socketId }: { socketId: string }) => {
    console.log({ socketId });
    store.dispatch({
      type: conferenceActions.waitingGuestDisconnected.type,
      payload: socketId,
    });
  }
);
socket.on(
  "participant-detail",
  ({ user }: { user: User }) => {
    store.dispatch({
      type: conferenceActions.participantDetail.type,
      payload: user,
    });
  }
);
socket.on(
  "moderator-toggle-audio-video",
  ({type,mute }: { type:string, mute:boolean }) => {
    store.dispatch({
      type: conferenceActions.moderatorAudioVideoToggleParticipant.type,
      payload: {
       type,mute
      },
    });
  }
);
socket.on(
  "audio-video-mute-status-changed",
  ({uuid,type,muted }: { uuid:string,type:string, muted:boolean }) => {
    store.dispatch({
      type: conferenceActions.participantAudioVideoMuteStatusChanged.type,
      payload: {
        uuid,type,muted 
      },
    });
  }
);
socket.on(
  "inform-presenter",
  ({uuid,jitsiId,enable}: { uuid:string,jitsiId:string, enable:boolean }) => {
    store.dispatch({
      type: conferenceActions.informPresenter.type,
      payload: {
        uuid,jitsiId,enable 
      },
    });
  }
);
socket.on(
  "ask-to-unmute-request",
  ({uuid,name,type,by}: { uuid:string,name:string,type:string, by:string }) => {
    store.dispatch({
      type: conferenceActions.askUnmuteRequest.type,
      payload: {
        uuid,
        name,
        type,
        by,
      },
    });
  }
);
socket.on(
  "updated-security-settings",
  ({securitySettings}: { securitySettings:ISecuritySettings }) => {
    store.dispatch({
      type: authActions.updatedSecuritySettings.type,
      payload: {
        securitySettings
      },
    });
  }
);
socket.on(
  "participant-name-changed",
  ({ uuid, newName }: { newName:string, uuid:string }) => {
    store.dispatch({
      type: conferenceActions.participantNameChanged.type,
      payload: {
        uuid, newName
      },
    });
  }
);
socket.on(
  "participant-name-changed-by-moderator",
  ({ uuid, newName }: { newName:string, uuid:string }) => {
    store.dispatch({
      type: conferenceActions.participantNameChangedByModerator.type,
      payload: {
        uuid, newName
      },
    });
  }
);
socket.on(
  "lower-all-hand-by-moderator",
  () => {
    store.dispatch({
      type: conferenceActions.lowerAllHandByModerator.type,
    });
  }
);
socket.on(
  "request-participant-toWaitingRoom-by-moderator",
  () => {
    store.dispatch({
      type: conferenceActions.requestbackToWaitingRoomParticipantByModerator.type,
    });
  }
);
socket.on(
  "request-remove-participant-by-moderator",
  () => {
    store.dispatch({
      type: conferenceActions.requestRemoveParticipantByModerator.type,
    });
  }
);


socket.on(
  "request-close-meeting-by-moderator",
  () => {
    store.dispatch({
      type: conferenceActions.requestCloseMeetingByModerator.type,
    });
  }
);
socket.on(
  "request-lower-hand-by-moderator",
  () => {
    store.dispatch({
      type: conferenceActions.requestLowerHandByModerator.type,
    });
  }
);
socket.on(
  "moderator-mute-all",
  ({ type, mute }: { type:string, mute:boolean }) => {
    store.dispatch({
      type: conferenceActions.muteAll.type,
    });
  }
);
socket.on(
  "participant-disconnected",
  ({ uuid }: { uuid:string }) => {
    store.dispatch({
      type: conferenceActions.disconnectedParticipant.type,
      payload:{uuid}
    });
  }
);
socket.on(
  "participant-disconnected",
  ({ uuid }: { uuid:string }) => {
    store.dispatch({
      type: conferenceActions.aboutToParticipantDisconnect.type,
      payload:{uuid}
    });
  }
);

