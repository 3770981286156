import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ALERT_TYPES, NOTIFICATION_TYPES } from './constants';
import { NotificationState } from './types';
export const initialState: NotificationState = {
    notificationType:'',
    notificationEvent: {
        message: '',
        name: '',
        autoClose: 5000,
      },
      alertType: ALERT_TYPES.SUCCESS, 
  };
  export const counterSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        
        askUnMuteNotification(state, action: PayloadAction<any>) {
            state.notificationEvent ={
                message: action.payload.message,
                name:action.payload.name,
                autoClose: false
            };
            state.notificationType =  action.payload.notificationType;
            state.alertType = ALERT_TYPES.SUCCESS
        },
        clearNotificationEvent(state, action: PayloadAction) {
            state.notificationEvent = initialState.notificationEvent;
        },
        guestJoiningToWaitingRoom(state, action: PayloadAction<any>) {
            state.notificationEvent ={
                message: action.payload.message,
                name: '',
                autoClose: 3000
            };
            state.notificationType =  action.payload.notificationType;
            state.alertType = ''; //show 'info' icon (not success or failure)
        },
        noteUpdateSuccess(state, action: PayloadAction<any>) {
            state.notificationEvent ={
                message: action.payload.message,
                name: '',
                autoClose: 3000
            };
            state.alertType = action.payload.alertType; //show 'success' icon (not success or failure)
        },
        setNotification(state, action: PayloadAction<any>) {
            state.notificationEvent ={
                message: action.payload.message,
                name: '',
                autoClose: false,
            };
            state.notificationType =  NOTIFICATION_TYPES.DEFAULT;
            state.alertType = action.payload.alertType
        }
    },
    
  });
  
  export const { actions: notificationActions, reducer: notificationReducer, name: notificationSliceKey } = counterSlice;