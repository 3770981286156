import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.app || initialState;

  export const selectAppStatus = createSelector(
    [selectDomain],
    appState => appState.status,
  );
  export const selectRoomData = createSelector(
    [selectDomain],
    appState => appState.roomData,
  );
 