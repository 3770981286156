import logo from 'images/branding/logo.png';
import './index.scss';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../auth/slice';
import { joinAsGuestRequest } from '../../app/actions';
import { selectCurrentUser } from '../../auth/selectors';
import { selectAppStatus } from '../../app/selectors';
import { AppStatus } from '../../app/types';
import mic from 'images/conference/mic(white).svg';
import micSlash from 'images/conference/mic-off(white).svg';
import videoCam from 'images/conference/videocam(white).svg';
import videoCamSlash from 'images/conference/videocam-off(white).svg';
import uparrow from 'images/conference/uparrow.svg';
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { selectRoomData } from '../../app/selectors'

import React, { useEffect,useRef,useState} from 'react';

export function DisplayNameInputView() {
  const videoRef = useRef(null);
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [audioMuted,setAudioMuted]=useState(true);
  const [videoMuted,setVideoMuted]=useState(true);
  const [videoStream,setVideoStream]=useState<any>(null);
  const currentUser = useSelector(selectCurrentUser);
  const appStatus = useSelector(selectAppStatus);
  const roomData = useSelector(selectRoomData);
  const [audiodevicesListPopoverOpen, setAudiodevicesListPopoverOpen] = useState(false);
  const [videodevicesListPopoverOpen, setVideodevicesListPopoverOpen] = useState(false);
  const [audioDevicesList,setAudioDevicesList]=useState<any>([]);
  const [videoDevicesList,setVideoDevicesList]=useState<any>([]);
  const [selectedAudioDevice,setSelectedAudioDevice]=useState<any>("");
  const [selectedVideoDevice,setSelectedVideoDevice]=useState<any>("");
  const [nameValue, setNameValue] = useState('');

  const toggleAudioListPopOver = () => setAudiodevicesListPopoverOpen(!audiodevicesListPopoverOpen);
  const toggleVideoListPopOver = () => setVideodevicesListPopoverOpen(!videodevicesListPopoverOpen);

  const onSubmit = ({ guestName }: { guestName: string }) => {
    dispatch(authActions.setCurrentUserName(guestName));
    dispatch(authActions.setPreferedAudioId(selectedAudioDevice));
    dispatch(authActions.setPreferedVideoId(selectedVideoDevice));
    dispatch(authActions.setCurrentUserAudioVideoStatus({audioMuted,videoMuted}))
  };

  useEffect(() => {
    if (roomData && roomData.meetingType === 1) {
      setNameValue(roomData.doctorName);
    }
  }, [roomData, dispatch]);

  useEffect(() => {
    if (currentUser.name) {
      dispatch(joinAsGuestRequest())
    }
  }, [currentUser.name, dispatch]);

  useEffect(() => {
    if (appStatus === AppStatus.WAITING_FOR_APPROVAL) {
      history.push('/waiting-view');
    }
  }, [appStatus, history]);

  useEffect(() => {
    // getVideo();
    getAvailbleMdiaDevices()
  }, []);

  useEffect(()=>{
    if (videoDevicesList.length>0 && audioDevicesList.length>0){
      setSelectedVideoDevice(videoDevicesList[0].deviceId);
      setSelectedAudioDevice(audioDevicesList[0].deviceId);
    }
    
  },[audioDevicesList,videoDevicesList])

  useEffect(()=>{
    if (selectedVideoDevice !="" && selectedAudioDevice !=""){
      getVideo();
    }
      
  },[selectedAudioDevice,selectedVideoDevice]);

  const getAvailbleMdiaDevices = async ()=>{
    await navigator.mediaDevices.getUserMedia({audio: true, video: true});
    navigator.mediaDevices.enumerateDevices()
    .then(gotDevices=>{
      let tempAudioDeviceList:any = [];
      let tempVideoDeviceList:any = [];
      gotDevices.map(device=>{
        if (device.kind==='audioinput'){
          tempAudioDeviceList.push({label:device.label,deviceId:device.deviceId})
        }
        else if (device.kind==='videoinput'){
          tempVideoDeviceList.push({label:device.label,deviceId:device.deviceId})
        }
        return null;
      });
      setAudioDevicesList(tempAudioDeviceList);
      setVideoDevicesList(tempVideoDeviceList);
    })
    .catch(error=>console.log(error))
  };

  useEffect(()=>{
    if (videoStream){
      videoStream.getVideoTracks()[0].enabled=!videoMuted;
      videoStream.getAudioTracks()[0].enabled=!audioMuted;
      
    }

  },[audioMuted,videoMuted,videoStream])

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId:selectedVideoDevice,width: 300 },audio:{deviceId:selectedAudioDevice} })
      .then(stream => {
        let video:any = videoRef.current;
        if (video){
          video.srcObject = stream;
          video.play();
          setVideoStream(video.srcObject)
        }
        
      })
      .catch(err => {
        console.error("error:", err);
      });
  };

  const renderDevicesList = (devicesList:any)=>{
    return(
    <>
      { devicesList.map((device:any)=>( <div key={device.deviceId} onClick={()=>{
        if (devicesList===audioDevicesList){
          if (selectedAudioDevice !=device.deviceId){
            setSelectedAudioDevice(device.deviceId);
            stopStreams()
          }
          setSelectedAudioDevice(device.deviceId);
        }
        else{
          if (selectedVideoDevice !=device.deviceId){
            setSelectedVideoDevice(device.deviceId);
            stopStreams()
          }
        }
      }}>{device.label}</div>))}
    </>
    )
  };

  const stopStreams=()=>{
    videoStream.getTracks().forEach((t: any) =>{
      t.stop()
    })
  }

  return (
    <div className="DISPLAY_NAME_INPUT--">
      <div className="innerBox">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <h2>You're about to join</h2>
        <div className="preTweaksDiv">
          <video
            playsInline
            ref={videoRef}
            autoPlay
            controls={false}
          />
          <div className="controlButtons">
            <div className="audioVideoBtn">
              <button className="secondary-button bigBtn" onClick={()=>setAudioMuted(!audioMuted)}>
                <img src={audioMuted ? micSlash : mic} alt="" />
              </button>
              <div id='audioDevicesList' className="miniBtn">
                <img src={uparrow} alt="" />
              </div>
            </div>
            

            <Popover
              placement='top'
              isOpen={audiodevicesListPopoverOpen}
              target={"audioDevicesList"}
              toggle={toggleAudioListPopOver}
            >
              <PopoverHeader>Select the preferred audio input</PopoverHeader>
              <PopoverBody>
                {renderDevicesList(audioDevicesList)}
              </PopoverBody>
            </Popover>

            <div className="audioVideoBtn">
              <button className="secondary-button bigBtn" onClick={()=>setVideoMuted(!videoMuted)}>
                <img src={videoMuted ? videoCamSlash : videoCam} alt="" />
              </button>
              <div id='videoDevicesList' className="miniBtn">
                <img src={uparrow} alt="" />
              </div>
            </div>
            <Popover
              placement='top'
              isOpen={videodevicesListPopoverOpen}
              target={"videoDevicesList"}
              toggle={toggleVideoListPopOver}
            >
              <PopoverHeader>Select the preferred video input</PopoverHeader>
              <PopoverBody>
                {renderDevicesList(videoDevicesList)}
              </PopoverBody>
            </Popover>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="nameInputDiv">
            <label>Enter Name</label>
            <input
              type="text"
              name="guestName"
              placeholder="John Doe"
              value={nameValue}
              onChange={e=>setNameValue(e.target.value)}
              ref={register({ required: true })}
            />
            {errors.guestName && errors.guestName.type === 'required' && (
              <div className="inputValidationError">Name is required</div>
            )}
            {errors.guestName && errors.guestName.type === 'minLength' && (
              <div className="inputValidationError">
                Name should be at least 2 characters
              </div>
            )}
          </div>
          <button type="submit" className="primary-button joinButton">Join Meeting</button>
        </form>
      </div>
    </div>
  );
}