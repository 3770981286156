import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { conferenceActions } from '../slice';
import { ROLE } from '../../app/types';
import { selectCurrentUser } from '../../auth/selectors';
import makePresenterImg from 'images/conference/make-presenter.svg';
import askToUnmute from 'images/conference/ask-to-unmute.svg';
import rename from 'images/conference/rename.svg';
import remove from 'images/conference/remove.svg';
import ellipsis from 'images/conference/ellipsis.svg';
import { Participant } from './../types'


type Props = {
  uuid: string;
  jitsiId: string;
  roles: string[];
  audioMuted: boolean;
  videoMuted: boolean;
  onRenameClick: () => void;
};
export function ParticipantOptions(props: Props) {

  const { uuid, jitsiId, roles, audioMuted, videoMuted } = props;
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [me, setMe] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  useEffect(() => {
    if (uuid === currentUser.uuid) {
      setMe(true)
    }
  }, [currentUser, uuid])

  const isModerator = () => {

    return currentUser.roles.includes(ROLE.MODERATOR)
  }

  const onMakePresenter = () => {
    dispatch(conferenceActions.makePresenter({ uuid, jitsiId, enable: !isPresenter() }))
  }
  const isPresenter = () => {
    return roles.includes(ROLE.PRESENTER)
  }
  const askToUnmute = (type: string) => {
    if (me && !isModerator()) {
      dispatch(conferenceActions.askToUnmute({ uuid, type: type, by: ROLE.GUEST }))
      setPopoverOpen(false);
    } else if (!me && isModerator()) {
      dispatch(conferenceActions.askToUnmute({ uuid, type: type, by: ROLE.MODERATOR }))
      setPopoverOpen(false);
    }
  }
  const showAskUnmuteVideo = () => {
    if (me && !isModerator() && currentUser.status.videoMuted ) {
      return true
    } else if (isModerator() && !me && videoMuted) {
      return true
    }
    return false
  }
  const showAskUnmuteAudio = () => {
    if (me && !isModerator() && currentUser.status.audioMuted) {
      return true
    } else if (isModerator() && !me && audioMuted) {
      return true
    }
    return false
  }
  const showParticipantMenuButton = () => {
    if (isModerator()) {
      return true
    } else if (!isModerator() && me) {
      return true
    }
    return false
  }
  const onRemoveParticipant = () => {
    dispatch(conferenceActions.removeParticipantByModerator({ uuid }))
  };
  const onbackToWaitingRoom = () => {
    dispatch(conferenceActions.participantBackToWaitingRoom({ uuid }))
  }


  
  return (
    <>
      {showParticipantMenuButton() && <button className="secondary-button admitButton" id={`participantEllipsis-${uuid}`}>
        <img src={ellipsis} alt="" />
      </button>}
      {showParticipantMenuButton() && <Popover placement="bottom" isOpen={popoverOpen} target={`participantEllipsis-${uuid}`} toggle={togglePopover} className="participantEllipsis_popover" trigger="legacy">
        <PopoverBody>
          {isModerator() && <button onClick={onMakePresenter}>
            <span>{isPresenter() ? "Remove Presenter" : `Make Presenter`}</span>
          </button>}
          {showAskUnmuteAudio() && <button onClick={() => askToUnmute('audio')}>
            {/* <img src={askToUnmute} alt="" /> */}
            <span>Ask to Unmute</span>
          </button>}
          {showAskUnmuteVideo() && <button onClick={() => askToUnmute('video')}>
            {/* <img src={askToUnmute} alt="" /> */}
            <span>Ask to Start Video</span>
          </button>}
          {(isModerator()&& !me) && <button onClick={onbackToWaitingRoom}>
            {/* <img src={remove} alt="" /> */}
            <span>Back to Waiting Room</span>
          </button>}
          {(isModerator() || me) && <button onClick={props.onRenameClick}>
            {/* <img src={rename} alt="" /> */}
            <span>Rename</span>
          </button>}
          {(isModerator()&& !me) && <button onClick={onRemoveParticipant}>
            {/* <img src={remove} alt="" /> */}
            <span>Remove</span>
          </button>}
        </PopoverBody>
      </Popover>}
    </>
  );
}