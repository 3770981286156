import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, CurrentUser, LoginData, ResetPasswordData, LoginTokenData } from './types';
import { RootState } from '../../store/store';
import { loginSucceeded } from './actions';
import { act } from 'react-dom/test-utils';
import { v4 as uuidv4 } from 'uuid';
import { joinedToConference } from '../conference/actions';
import { conferenceActions } from '../conference/slice';
import { ROLE } from 'features/app/types';
import { DOMAIN,BACKEND_API } from '../../configs';
import { API } from './constants'
import { notificationActions } from "../notification/slice";
import { ALERT_TYPES } from 'features/notification/constants';


export const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  currentUser: {
    name: '',
    roles: [],
    uuid: uuidv4(),
    socketId: '',
    jitsiId:'',
    status:{
      audioMuted: true,
      videoMuted: true,
      handRaised: false,
    }

  },
  securitySettings:{
    allowParticipantToUnmuteThemSelf: false,
    waitingRoomProtected: true,
  },
  errorMessage: '',
  successMessage: '',
  settings:{
    enableWaitingRoom:true,
    enableJoinBeforeHost: false,
    muteParticipantUponEntry:false,
  },
  preferedAudioId:'default',
  preferedVideoId:'default',
  meetToken:undefined
};

const getMeetingToken = async(room:string,moderator:boolean) => {
 
  var options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${DOMAIN}-token`)}`,
      },
    method: API.GET_MEET_TOKEN.method,
    body: JSON.stringify({
      room,
      moderator
    })
  }
  let response = await fetch(`${BACKEND_API}${API.GET_MEET_TOKEN.path}`,options);
  return await response.json();
}
export const login = createAsyncThunk<any, LoginData, { state: RootState }>(
  'auth/login',
  async (loginData,  {rejectWithValue,dispatch}  ) => {
    try {
      var options = {
        headers: {
          'Content-Type': 'application/json',
          },
        method: API.SHELL_LOGIN.method,
        body: JSON.stringify({
          email:loginData.email,
          password:loginData.password,
        })
      }
      let response = await fetch(`${BACKEND_API}${API.SHELL_LOGIN.path}`,options);
      if (response){
        let result = await response.json();
        if (result.success){
          sessionStorage.setItem(`${DOMAIN}-token`, result.data.token);
          const meetTokenData = await getMeetingToken( loginData.room, true)
          if(meetTokenData.success){
            sessionStorage.setItem(`${DOMAIN}-meet-token`, meetTokenData.data.meetToken);
            dispatch(authActions.loginSuccess(meetTokenData.data))
            return meetTokenData.data
          } else {
            dispatch(authActions.loginFailed(meetTokenData.message))
            // dispatch(notificationActions.setNotification({message:meetTokenData.message,alertType:ALERT_TYPES.FAILURE}))
            return rejectWithValue(result.message)
          }
        }
        else{
          dispatch(authActions.loginFailed(result.message))
          // dispatch(notificationActions.setNotification({message:result.message, alertType:ALERT_TYPES.FAILURE}))
          return rejectWithValue(result.message)
        }
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
    
  }
);

export const sendForgotPasswordToken = createAsyncThunk<any, {email: string}, { state: RootState }>(
  'auth/forgot-password',
   async(email) => {
     var options = {
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwMmE0ZTNkNjk5MWU4MWFiODA1ZTA3MiIsImVtYWlsIjoic2tvZGlzaW5naGUxMDBAZ21haWwuY29tIiwiaWF0IjoxNjE3NDE4NTgxLCJleHAiOjE2MTc0MjIxODF9.RPtaFxb1MD_kbWOl9JaESWeJjJbeNC5-Qe_rYWc0VmQ`
      },
      method: 'POST',
      body: JSON.stringify(email)
     };
    //  console.log('this is my email>>>',email);
     await fetch(`${BACKEND_API}${API.FORGOT_PASSWORD.path}`,options);
    //  alert(response.json());

   });

export const resetPassword = createAsyncThunk<any, ResetPasswordData, { state: RootState }>(
'auth/reset-password',
  async(ResetPasswordData) => {
    var options = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${DOMAIN}-token`)}`
    },
    method: 'POST',
    body: JSON.stringify(ResetPasswordData)
    };
    // console.log('this is my reset-password data>>>',ResetPasswordData);
    await fetch(`${BACKEND_API}${API.RESET_PASSWORD.path}`,options);
    // alert(response.json());

  });
  export const loginByToken = createAsyncThunk<any, LoginTokenData, { state: RootState }>(
    'auth/loginByToken',
    async (loginTokenData, {rejectWithValue,dispatch} ) => {
      try {
        const options = {
          method: API.LOGIN_BY_TOKEN.method,
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${loginTokenData.token}`
           },
        };
        const authResponse = await fetch(`${BACKEND_API}${API.LOGIN_BY_TOKEN.path}`,options);
        const authData = await authResponse.json();
        if(authData.success){
          sessionStorage.setItem(`${DOMAIN}-token`,authData.token);
          const meetTokenData = await getMeetingToken( loginTokenData.room, true)
          if(meetTokenData.success){
            sessionStorage.setItem(`${DOMAIN}-meet-token`, meetTokenData.data.meetToken);
            dispatch(authActions.loginSuccess(meetTokenData.data))
            return meetTokenData.data
          } else {
            dispatch(authActions.loginFailed(meetTokenData.message))
            dispatch(notificationActions.setNotification({message:meetTokenData.message,alertType:ALERT_TYPES.FAILURE}))
            return rejectWithValue(authData.message)
          }
          
          
        } else {
          dispatch(authActions.loginFailed(authData.message))
          dispatch(notificationActions.setNotification({message:authData.message,alertType:ALERT_TYPES.FAILURE}))
          return rejectWithValue(authData.message)
        }
      } catch (error) {
        return rejectWithValue(error.response.data)
      }

    }
  )


export const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.isAuthenticated = false
      state.errorMessage = action.payload;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.settings=action.payload.settings
      state.loading = false;
      state.isAuthenticated = true;
      state.currentUser.name = action.payload.user.name;
      state.currentUser.roles = ['moderator']
    },
    loginByToken(state, action: PayloadAction<any>) {
      state.loading = false;
      state.currentUser = { ...state.currentUser, ...action.payload };
        state.isAuthenticated = true;
    },
    setCurrentUserName(state, action: PayloadAction<string>) {
      state.currentUser.name = action.payload;
    },
    setPreferedAudioId(state, action: PayloadAction<string>) {
      state.preferedAudioId = action.payload;
    },
    setPreferedVideoId(state, action: PayloadAction<string>) {
      state.preferedVideoId = action.payload;
    },
    setCurrentUserAudioVideoStatus(state, action: PayloadAction<any>) {
      state.currentUser.status.audioMuted = action.payload.audioMuted;
      state.currentUser.status.videoMuted = action.payload.videoMuted;
    },
    updateSecuritySettings(state, action: PayloadAction<any>) {

    },
    updatedSecuritySettings(state, action: PayloadAction<any>) {
      state.securitySettings = action.payload.securitySettings
    },
    // sendForgotPasswordToken(state, action: PayloadAction<any>) {
    //   state.loading = true;
    //   state.errorMessage = '';
    //   state.successMessage = '';
    // },
    sendForgotPasswordTokenFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.errorMessage = action.payload;
      state.successMessage = '';
    },
    sendForgotPasswordTokenSucceeded(state, action: PayloadAction<string>) {
      state.loading = false;
      state.successMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        // state.currentUser = { ...state.currentUser, ...action.payload };
        state.isAuthenticated = true;
      })
      .addCase(loginByToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginByToken.fulfilled, (state, action) => {
        state.loading = true;
        // state.currentUser = { ...state.currentUser, ...action.payload };
        state.isAuthenticated = true;
      })
      .addCase(joinedToConference, (state,action) => {
        state.currentUser.jitsiId = action.payload;
      })
      .addCase(conferenceActions.audioVideoMuteStatusChanged, (state,action) => {
        if(action.payload.type === 'audio'){
          state.currentUser.status.audioMuted = action.payload.muted;
        }
        else if(action.payload.type === 'video'){
          state.currentUser.status.videoMuted = action.payload.muted;
        }
        
      })
      .addCase(conferenceActions.informPresenter, (state,action) => {
        if( state.currentUser.jitsiId === action.payload.jitsiId){
          if(action.payload.enable){
            state.currentUser.roles.push(ROLE.PRESENTER)
          } else {
            state.currentUser.roles = state.currentUser.roles.filter(r=> r!==ROLE.PRESENTER)
          }
        }
        
        
      })
      .addCase(conferenceActions.joinedAsModerator, (state,action) => {
        state.securitySettings = action.payload.securitySettings;
      })
      .addCase(conferenceActions.joinedAsGuest, (state,action) => {
        state.securitySettings = action.payload.securitySettings;
      })
      .addCase(conferenceActions.raiseHandUpdated, (state,action) => {
        
        const {id,handRaised  } = action.payload
        if(state.currentUser.jitsiId === id){
          state.currentUser.status.handRaised = handRaised;
        }
      })
  }
});

export const { actions: authActions, reducer: authReducer, name: authSliceKey } = counterSlice;