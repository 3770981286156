import React, { useEffect, useState } from 'react';
import {
  UncontrolledCarousel
} from 'reactstrap';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {selectAppStatus, selectRoomData} from '../../app/selectors';
import { RESOURCES_BUCKET_URL } from 'configs';


export function AdCarousel() {

  const roomData = useSelector(selectRoomData);
  const [adds, setAdds] = useState<{src:string,key:string}[]>([])
  // create an array and pass
useEffect(()=>{
  const adds = roomData?.advertisementList.map((ad,id)=>{
    return {
      src: `${RESOURCES_BUCKET_URL}/${ad.adKey}`,
      key: `${id}`
    }
  })
  if(adds){
    setAdds(adds)
  }


},[roomData])

  return (
    <div className="AD_CAROUSEL--">
        <UncontrolledCarousel className="adsCarousel" items={adds} />
    </div>
  );
}