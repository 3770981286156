import {
  MemoryRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { InitialLoader } from './InitialLoader';
import { WaitingView } from '../waitingRoom/WaitingView';
import { WaitingForHost } from '../waitingRoom/WaitingForHost';
import { DisplayNameInputView } from '../waitingRoom/DisplayNameInputView';
import { ConferenceView } from '../conference/ConferenceView';
import { JoinRequestRejected } from '../waitingRoom/JoinRequestRejected';
import { ThankYouPage } from '../conference/ThankYouPage'
import { Login } from '../auth/Login';
import { Signup } from '../auth/Signup';
import { ForgotPassword } from '../auth/ForgotPassword';
import { SetNewPassword } from '../auth/SetNewPassword';
import './App.scss';
import '../socket/socketListener';
import { Home } from '../Home';
import { Notification } from '../notification'
import packageJson from "../../../package.json";
import { getBuildDate } from "../../utils/utils";
import withClearCache from "../../ClearCache";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const ClearCacheComponent = withClearCache(MainApp);




function MainApp() {
  
  return (
    <>
    <Notification/>
    <Router>
      <Switch>
        <Route exact path="/" component={InitialLoader} />
        <Route path="/set-new-password" component={SetNewPassword} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/waiting-view" component={WaitingView} />
        <Route path="/waiting-for-host" component={WaitingForHost} />
        <Route path="/display-name" component={DisplayNameInputView} />
        <Route path="/conference" component={ConferenceView} />
        <Route path="/home" component={Home} />
        <Route path="/join-request-rejected" component={JoinRequestRejected} />
        <Route path="/thank-you" component={ThankYouPage} />
      </Switch>
    </Router>
    <p className="buildDate-text">Build date: {getBuildDate(packageJson.buildDate)}</p>
    </>
  );
}
function App() {
  return <ClearCacheComponent />;
}

export { App };
