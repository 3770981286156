import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.notification || initialState;

export const selectNotification = createSelector(
  [selectDomain],
  notificationState => notificationState,
);
export const selectNotificationEvent = createSelector(
  [selectDomain],
  notificationState => notificationState.notificationEvent,
);

