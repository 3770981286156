import { EachParticipant } from '../EachParticipant';
import React, { useDispatch, useSelector } from 'react-redux'
import search from 'images/conference/search.svg';
import './index.scss';
import { selectParticipantList } from './../selectors';
import { selectCurrentUser } from '../../auth/selectors';
import { Participant } from './../types';
import { MuteAllButton } from '../MuteAllButton';
import { UnMuteAllButton } from '../UnMuteAllButton';
import { useState,useEffect } from 'react';


export function ParticipantsList() {

  const participantList = useSelector(selectParticipantList);
  const currentUser = useSelector(selectCurrentUser);
  const [searchParticipant, setSearchParticipant] = useState('');

  
  return (
    <div className="PARTICIPANTS_LIST--">
      <h4 className="sectionTitle">
        In the Meeting ({participantList.length + 1})
      </h4>
      <div className="searchBar">
        <img src={search} alt="" />
        <input type="text" placeholder="Search" onChange={e => setSearchParticipant(e.target.value)} />
      </div>
      <div className="listOfParticipants">
        <EachParticipant key={currentUser.uuid} participant={currentUser} />
        {
          participantList.filter(p => (new RegExp(searchParticipant, 'i')).test(p.name)).filter(p=> p.uuid && p.jitsiId).map((p: Participant) => (<EachParticipant key={p.uuid} participant={p} />))
        }
      </div>
      {/* Moved the mute/unmute buttons to Sidebar */}
      {/* <MuteAllButton />
      <UnMuteAllButton /> */}
    </div>
  );
}