export enum AppStatus {
  UNSUPPORTED_BROWSER,
  IOS_BROWSER,
  ANDROID_BROWSER,
  THANK_YOU,
  READY_FOR_CONNECT,
  INVALID_ROOM_ID,
  INITIALIZING,
  WAITING_FOR_APPROVAL,
  SIGN_IN_AS_HOST,
  NOT_STARTED,
  STARTED,
  JOINED,
  MEETING_ENDED,
  JOIN_REQUEST_REJECTED
};

export interface AppState {
  status: AppStatus;
  roomData: RoomData | null;
  loading: boolean;
}
export interface IAdvertisementList{
  fileName:string
  adKey:string
  size:number
  fileFormat:string
  uploadedDate:string
}

export type RoomData = {
  _id: string;
  startTime: Date;
  endTime: Date;
  companyName: string;
  drugName: string;
  doctorName: string;
  doctorEmail: string;
  representativeName: string;
  representativeEmail: string;
  meetingId: string;
  recordingFileKey?: string;
  adminNotes: string;
  repNotes: string;
  drugImageKey:string;
  meetingType: number;
  advertisementList:IAdvertisementList[]
}

// root types 
export type User = {
  socketId: string;
  uuid: string;
  name: string;
  roles: string[];
  jitsiId: string;
  status: {
    audioMuted: boolean;
    videoMuted: boolean;
    handRaised: boolean;
  }
};

export enum ROLE {
  MODERATOR = 'moderator',
  PRESENTER ='presenter',
  GUEST = 'guest',
}
export enum MeetingType {
  PERSONAL_MEETING,
  DOCTOR_REPRESENTATIVE_MEETING,
}
