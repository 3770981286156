import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.auth || initialState;

  export const selectIsLoading = createSelector(
    [selectDomain],
    authStateState => authStateState.loading,
  );

  export const selectIsAuthenticated = createSelector(
    [selectDomain],
    authStateState => authStateState.isAuthenticated,
  );

  export const selectIsErrorMessage = createSelector(
    [selectDomain],
    authStateState => authStateState.errorMessage,
  );

  export const selectCurrentUser = createSelector(
    [selectDomain],
    authStateState => authStateState.currentUser,
  );
  export const selectCurrentUserStatus = createSelector(
    [selectDomain],
    authStateState => authStateState.currentUser.status,
  );
  export const selectPreferedAudioId = createSelector(
    [selectDomain],
    authStateState => authStateState.preferedAudioId,
  );
  export const selectPreferedVideoId = createSelector(
    [selectDomain],
    authStateState => authStateState.preferedVideoId,
  );
  export const selectMuteParticipantsUponEntry = createSelector(
    [selectDomain],
    authStateState => authStateState.settings.muteParticipantUponEntry,
  );
  export const selectSecuritySettings = createSelector(
    [selectDomain],
    authStateState => authStateState.securitySettings,
  );
  export const selectCurrentUserRoles = createSelector(
    [selectDomain],
    authStateState => authStateState.currentUser.roles,
  );
