import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { conferenceActions } from '../slice';
import users from 'images/conference/users.svg';
import note from 'images/conference/notes.svg';
import editNote from 'images/conference/edit-note.svg';
import './index.scss';
import { selectGuestRequestCount, selectRepNotes } from '../selectors';
import React from 'react';
import Popup from 'reactjs-popup';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { WYSIWIGToolbarOptions } from '../types';
import { selectCurrentUserRoles } from '../../auth/selectors';
import { selectRoomData } from '../../app/selectors';
import { updateRepNotes } from '../../conference/slice';
import { MeetingType } from 'features/app/types';
import { stateFromHTML } from 'draft-js-import-html';

export function NarrowSidebar() {
  const currentUserRole = useSelector(selectCurrentUserRoles);
  const waitingCount = useSelector(selectGuestRequestCount);
  const roomData = useSelector(selectRoomData);
  const repNotes = useSelector(selectRepNotes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (repNotes) {
      setEditorState(
        EditorState.createWithContent(
          stateFromHTML(repNotes),
        ),
      );
    }
  }, [repNotes]);

  useEffect(() => {
    document.getElementById("expandSidebarButton")?.classList.add("highlight");
    setTimeout(() => {
      document.getElementById("expandSidebarButton")?.classList.remove("highlight");
    }, 2400)
  }, [waitingCount]);

  const adminNotes = roomData?.adminNotes ? roomData.adminNotes : '<p>No admin notes added!</p>';
  const createMarkup = () => {
    return { __html: adminNotes };
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const WYSIWYGEditor = () => (
    <Editor
      editorState={editorState}
      wrapperClassName="note-editor-wrap"
      editorClassName="note-editor"
      onEditorStateChange={onEditorStateChange}
      toolbar={WYSIWIGToolbarOptions}
    />
  );

  const addNote = () => {
    const _repNotes = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    const repNoteData = {
      meetingId: roomData?.meetingId ? roomData.meetingId : '',
      repNotes: _repNotes
    }
    dispatch(updateRepNotes(repNoteData))
  };

  const isScheduleMeet = () => {
    if(roomData?.meetingType === MeetingType.DOCTOR_REPRESENTATIVE_MEETING){
      return true;
    }
    return false;
  }
  return (
    <div className="NARROW_SIDEBAR--">
      <div className="inner">
        <button id="expandSidebarButton" className="expandButton primary-button" onClick={() => dispatch(conferenceActions.toggleSidebar())}>
          <img src={users} alt="" />
          {waitingCount > 0 && <span>{waitingCount}</span>}
        </button>
      </div>
      {currentUserRole.includes('moderator') && (
      <>
        {isScheduleMeet()  &&<div className="inner">
          <Popup
            className="viewNotePopup"
            trigger={
              <button className="viewNotes" title="View note">
                <img src={note} alt="Admin-Notes" />
              </button>
            }
            position="left top"
          >
            <div dangerouslySetInnerHTML={createMarkup()} />
          </Popup>
        </div>}
       {isScheduleMeet()  && <div className="inner">
          {/* {editorOpen ? ( */}
            <Popup
              className="addNotePopup"
              position="left top"
              closeOnDocumentClick
              trigger={
                <button className="addNotes" title="Add a note">
                  <img src={editNote} alt="Admin-Notes" />
                </button>
              }
            >
              <>
                <WYSIWYGEditor />
                <div className="buttonWrap">
                  <button className="primary-button updateButton" onClick={addNote}>Update Note</button>
                </div>
              </>
            </Popup>
          {/* ) : (
            <button className="addNotes" title="Add a note" onClick={()=> setEditorOpen(!editorOpen)}>
              <img src={editNote} alt="Admin-Notes" />
            </button>
          )} */}

        </div>}
      </>
      )}
    </div>
  );
}