import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import alertTick from 'images/conference/alert-tick.svg';
import alertClose from 'images/conference/alert-close.svg';
import alertInfo from 'images/conference/alert-info.svg';
import { selectNotification, selectNotificationEvent} from './selectors';
import { notificationActions } from './slice';
import { ALERT_TYPES, NOTIFICATION_TYPES } from '../notification/constants';
import { conferenceActions } from '../conference/slice';
import AskUnmuteIcon from 'images/conference/ask-unmute';
import { 
    selectMuteRequest,
   } from "../conference/selectors";
import { ROLE } from 'features/app/types';
import './index.scss';


export function Notification() {
    const dispatch = useDispatch();
    const notification = useSelector(selectNotification);
    const notificationEvent = useSelector(selectNotificationEvent);
    const muteRequest = useSelector(selectMuteRequest)

    const getIcon = () => {
      switch(notification.alertType){
          case ALERT_TYPES.SUCCESS:
              return alertTick
          case ALERT_TYPES.FAILURE:
            return alertClose;
          default:
            return alertInfo;          
      }
  }

    const Msg = () => (
        <div className="msg-left">
          <img src={getIcon()} alt="alertIcon" />
          <div>{notificationEvent.message}</div>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const onClose = () => {
        dispatch(notificationActions.clearNotificationEvent());
      };
    
      const askUnmuteRequestAccepted = () => {
          
        if(muteRequest.uuid){
            if(muteRequest.by === ROLE.GUEST){
                dispatch(conferenceActions.moderatorRequestAudioVideoToggleParticipant({ uuid:muteRequest.uuid, mute: false, type:muteRequest.type}))
            } else {
                dispatch(conferenceActions.audioVideoToggle({muteRequest, mute:false, type:muteRequest.type}))
            }
            
        }
       
        onClose()
      }

      const AskUnMuteMsg = () => (
        <div className="askedToUnmute">
          {/* <img src={alertTick} alt="alertTick" /> */}
          <div className="headerRow">
            <AskUnmuteIcon />
            <div>{notificationEvent.message}</div>
          </div>
          <div className="buttonsRow">
            <button className="primary-button" onClick={askUnmuteRequestAccepted}>Unmute</button>
            <button className="secondary-button" onClick={() =>  dispatch(notificationActions.clearNotificationEvent())}>Close</button>
          </div>
        </div>
      );
    const getMessage = () => {
        switch(notification.notificationType){
            case NOTIFICATION_TYPES.ASK_UNMUTE_EVENT:
                return <AskUnMuteMsg />
            default:
              return <Msg />           
        }
    }
    console.log('notificationEvent.message',notificationEvent.message)
    useEffect(() => {
        if (notificationEvent.message) {
          toast(getMessage(), {
            position: 'top-right',
            autoClose: notificationEvent.autoClose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: onClose,
          });
        }
      }, [notificationEvent.message]);

    const getAlertClass = () => {
      switch(notification.alertType){
        case ALERT_TYPES.SUCCESS: 
          return 'successAlert'
        case  ALERT_TYPES.FAILURE: 
          return 'failureAlert'
        default: 
          return 'defaultAlert'
      }
    }  
    return (
        <ToastContainer
          className={`customAlert ${getAlertClass()}`}
          position="top-center"
          autoClose={notificationEvent.autoClose ? notificationEvent.autoClose : 3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    );
}