import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { DOMAIN } from '../../../configs';
import {selectAppStatus, selectRoomData} from '../../app/selectors';
import { AppStatus, MeetingType } from '../../app/types';
import {Header} from '../../header';
import meetingPic from 'images/premeeting/casual-meeting.png';
import { authActions, loginByToken } from '../../auth/slice';
import { UrlParser } from 'url-params-parser';
import { RESOURCES_BUCKET_URL } from '../../../configs';
import vvalogo from 'images/branding/logo.png';
import { AdCarousel } from '../AdCarousel'
import './index.scss';
var jwt = require('jsonwebtoken');

export function WaitingForHost() {

  const history = useHistory();
  const dispatch = useDispatch();
  const appStatus = useSelector(selectAppStatus);
  const roomData = useSelector(selectRoomData);
  useEffect(() => {
    if (appStatus === AppStatus.STARTED) {
      history.push('/display-name')
    }
    const token = getParameter('token')
    if(token){
      sessionStorage.setItem(`${DOMAIN}-token`, token)
      const parsedUrl = UrlParser(window.location.href, '/:room');
      if(parsedUrl.namedParams.room !== '/'){
        dispatch(loginByToken({token, room: parsedUrl.namedParams.room}))
        history.push('/login')
      }
    }
  }, [appStatus]);
  const getParameter = (paramName:string) => {
    var searchString = window.location.search.substring(1),
        i, val, params = searchString.split("&");
  
    for (i=0;i<params.length;i++) {
      val = params[i].split("=");
      if (val[0] == paramName) {
        return val[1];
      }
    }
    return null;
  }
  const isScheduleMeet = () => {
    if(roomData?.meetingType === MeetingType.DOCTOR_REPRESENTATIVE_MEETING){
      return true;
    }
    return false;
  }
  return (
    <div className="WAITING_FOR_HOST--">
      <Header comingFrom="WAITING_FOR_HOST" />
      <div className="content">
        <div className="topHalf">
          <div className="carouselWrapper">
            <AdCarousel />
          </div>
          {/* <img src={`${RESOURCES_BUCKET_URL}/${roomData?.drugImageKey}`} alt="" /> */}
          <h1>Welcome to the meeting{isScheduleMeet() && <span>, Dr. {roomData?.doctorName}</span>}</h1>
          <h2>Please wait while the meeting is started</h2>
        </div>
        <h4 className="bottomHalf">
          <div className="isHost">
            <div>Or if you are the presenter,</div>
            <Link to="/login">Sign in</Link>
            <div>to start</div>
          </div>
          <div className="poweredBy">
            <div>Powered by</div>
            <img src={vvalogo} alt="" />
          </div>
        </h4>
      </div>
    </div>
  );
}