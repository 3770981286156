import React from 'react';
import './index.scss';
export function ButtonLoader() {
    return (
        <div className="loadingio-spinner-rolling-400e5tfdbje">
            <div className="ldio-19929li0i5u">
                <div></div>
            </div>
        </div>
    );
}