import socketIOClient from 'socket.io-client';
import { store } from "../../store/store";
import { SOCKET_ENDPOINT } from '../../configs';
import { ROLE } from 'features/app/types';

let reconnecting = false;
let reconnected = false;
const socket = socketIOClient(SOCKET_ENDPOINT);

socket.on('disconnect', () => {
    console.warn('disconnected from socket server...');
    if (!reconnecting) {
      reconnecting = true;
      console.log(socket.id); // undefined
      window.setTimeout(() => {
        reconnected = true;
      }, 1000);
    }
});
socket.on('connect', (data:any) => {
    console.info('Connected to the socket server..',data);
    console.log(socket.id);
    reconnecting = false;
    if (reconnected) {
      reconnectUsers();
      reconnected = false;
    }
});
const reconnectUsers = () => {
    console.log('reconnect user');
    const state = store.getState();
    if (state.auth.currentUser.name !== '') {
      if (state.auth.currentUser.roles.includes(ROLE.MODERATOR)) {
        socket.emit('join-moderator', {
          room: window.location.pathname.split('/')[1],
          startRoomProtected: state.auth.securitySettings.waitingRoomProtected,
            user: {
            name: store.getState().auth.currentUser.name,
            socketId: socket.id,
            uuid: store.getState().auth.currentUser.uuid,
            roles: store.getState().auth.currentUser.roles,
            status:  store.getState().auth.currentUser.status
            },
            reJoin: true,
        });
      } else {
        console.log('join-guest', socket.id);
        socket.emit('join-guest', {
          room: window.location.pathname.split('/')[1],
          reJoin: true,
          user: {
            name: store.getState().auth.currentUser.name,
            socketId: socket.id,
            uuid: store.getState().auth.currentUser.uuid,
            roles: store.getState().auth.currentUser.roles,
            status:  store.getState().auth.currentUser.status
            },
        });
      }
    } else {
      window.location.reload();
    }
  };


export { socket };