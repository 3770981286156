import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState, AppStatus, RoomData } from './types';
import { RootState } from '../../store/store';
import { login } from '../auth/slice';
import { App } from './App';
import { meetingStartedAlready, joinAsGuestRequest, waitForModerator } from './actions';
import { localUserHangedUp, joinRequestRejected } from '../conference/actions';
import { conferenceActions } from '../conference/slice';
import { authActions } from '../auth/slice'
import { DOMAIN,BACKEND_API } from '../../configs';
import { API } from './constants';

export const initialState: AppState = {
  status: AppStatus.INITIALIZING,
  roomData: null,
  loading: false,
};

export const getRoomData = createAsyncThunk<RoomData, string, { state: RootState }>(
  'app/getRoomData',
  async (room, { getState } ) => {
    let options = {
      headers: {
        'Content-Type': 'application/json',
        },
      method: API.GET_MEETING_DATA.method,
    }
    let response = await fetch(`${BACKEND_API}${API.GET_MEETING_DATA.path}/${room}`,options);
    const responseData = await response.json()
    if(responseData.success){
      return responseData.data;
    } else{
      return null;
    }
  }
)

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoomData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRoomData.fulfilled, (state, action) => {
        state.loading = false;
        state.roomData = action.payload
        state.status = AppStatus.READY_FOR_CONNECT;
      })
      .addCase(getRoomData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(waitForModerator, (state) => {
        state.status = AppStatus.NOT_STARTED;
      })
      .addCase(meetingStartedAlready, (state) => {
        state.status = AppStatus.STARTED;
      })
      .addCase(joinAsGuestRequest, (state) => {
        state.status = AppStatus.WAITING_FOR_APPROVAL;
      })
      .addCase(conferenceActions.joinedAsModerator, (state) => {
        state.status = AppStatus.JOINED;
      })
      .addCase(conferenceActions.joinedAsGuest, (state) => {
        state.status = AppStatus.JOINED;
      })
      .addCase(joinRequestRejected, (state) => {
        state.status = AppStatus.JOIN_REQUEST_REJECTED;
      })
      .addCase(localUserHangedUp, (state) => {
        state.status = AppStatus.MEETING_ENDED;
      })
      .addCase(authActions.loginSuccess, (state, action) => {
        state.roomData = Object.assign({...state.roomData, ...(action.payload.informationsToHost)})
      });
  } 
});

export const { actions: appActions, reducer: appReducer, name: appSliceKey } = counterSlice;