import permission from 'images/premeeting/permission.svg';
import React from 'react';
import './index.scss';

export function JoinRequestRejected() {
  return (
    <div className="JOIN_REQUEST_REJECTED--">
      <div className="inner">
        <img src={permission} alt="lock" />
        <div className="rightSide">
          <h1>Access Forbidden!</h1>
          <div>Your join request was rejected by the host.</div>
        </div>
      </div>
    </div>
  );
}