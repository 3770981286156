export interface ConferenceState {
  loading: boolean;
  roomWaitingFor?: string;
  hangedUp: boolean;
  roomJoinedTo?: string;
  allowedJoinToRoom: boolean;
  localAudioMuted: boolean;
  localVideoMuted: boolean;
  displayName: string;
  guestRequests: GuestRequest[];
  participants: Participant[];
  isSidebarOpen: boolean;
  moderatorAudioVideoToggleParticipant:{
    mute: boolean;
    type: string;
  }
  toggleAudioVideo:{
    mute: boolean;
    type: string;
  },
  changeDisplayName:{
    newName: string;
    uuid: string;
  },
  changePresenter:{
    jitsiId:string,
    uuid:string,
    enable: boolean,
  },
  requestToUnmute:{
    uuid:string,
    name:string,
    type:string,
    by:string
  },
  requestLowerAllHandByModerator: number;
  requestLowerHandByModerator: number;
  requestRemoveParticipant: boolean;
  requestCloseMeeting: boolean;
  iamToggleRaisedHand: number;
  isGuestTokenAvailable: boolean;
  muteAll: number;
  repNotes?: string;
}

export enum roomStatus {
  READY_FOR_CONNECT,
  WAITING_FOR_APPROVAL,
  SIGN_IN_AS_HOST,
  NOT_STARTED,
  STARTED,
  JOINED,
  MEETING_ENDED,
}

export type GuestRequest = {
  socketId: string;
  name: string;
  uuid: string;
};

export type Participant = {
  socketId: string;
  uuid: string;
  name: string;
  roles: string[];
  jitsiId: string;
  status: {
    audioMuted: boolean;
    videoMuted: boolean;
    handRaised: boolean;
  };
};
export type GuestData = {
  room?: string;
  name: string;
}

export type RepNoteData = {
  meetingId: string;
  repNotes: string;
}

export const WYSIWIGToolbarOptions = {
  options: ['blockType', 'fontSize', 'list', 'textAlign'],
};
