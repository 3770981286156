
import React from 'react';
import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from 'images/branding/logo.png';
import { RESOURCES_BUCKET_URL } from 'configs';
import { selectRoomData } from 'features/app/selectors'
import './index.scss';

interface Props {
    comingFrom: string
}

export function Header(props: Props) {
    const roomData = useSelector(selectRoomData);
    const drugImageUrl = `${RESOURCES_BUCKET_URL}/${roomData?.drugImageKey}`
    return (
      <div className="HEADER--">
          <div className="inner">
              <a className="logoLink" href="/">
                  <img src ={roomData?.drugImageKey ? drugImageUrl : Logo} alt="company-logo" />
              </a>
              <div>
              {props.comingFrom === 'WAITING_FOR_HOST' && <Link className="secondary-button loginLink" to="/login">Host login</Link>}
              </div>
          </div>
      </div>
    );
  }