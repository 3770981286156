export interface AuthState {
  loading: boolean;
  errorMessage?: string;
  successMessage?: string;
  currentUser: CurrentUser;
  isAuthenticated: boolean,
  securitySettings: ISecuritySettings,
  settings:ISettings,
  preferedAudioId:string,
  preferedVideoId:string,
  meetToken:string | undefined
}

export type LoginData = {
  email: string;
  password: string;
  room:string;
}
export type LoginTokenData = {
  token: string;
  room: string;
}
export type ResetPasswordData = {
  newPassword: string;
  token: string;
}

export type CurrentUser = {
  name: string;
  roles: string[];
  uuid: string;
  socketId: string;
  jitsiId: string;
  status: {
    audioMuted: boolean;
    videoMuted: boolean;
    handRaised: boolean;
  };
  
};
export interface ISecuritySettings{
  allowParticipantToUnmuteThemSelf:boolean;
  waitingRoomProtected: boolean;
};
export interface ISettings{
  enableWaitingRoom:boolean;
  enableJoinBeforeHost: boolean;
  muteParticipantUponEntry:boolean;
};
export enum SECURITY_SETTINGS_FIELDS {
  ALLOW_PARTICIPANT_TO_UNMUTE_THEM_SELF = 'ALLOW_PARTICIPANT_TO_UNMUTE_THEM_SELF',
  WAITING_ROOM_PROTECTED ='WAITING_ROOM_PROTECTED',
}

