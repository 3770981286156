import React, { useState, useRef } from 'react';
import logo from 'images/branding/logo.png';
import { ButtonLoader } from 'features/buttonLoader';
import './index.scss';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../slice';
// import { useLocation } from 'react-router-dom';
// import queryString from 'query-string';
import { UrlParser } from 'url-params-parser';

type ResetPasswordData = {
  newPassword: string;
  reTypedNewPassword: string;
};

export function SetNewPassword() {
  const parsedUrl = UrlParser(window.location.href);
  const [success, setSuccess] = useState(false);
  const { register, errors, handleSubmit, watch } = useForm<ResetPasswordData>();
  const password = useRef({});
  password.current = watch("newPassword", "");
  // let location = useLocation();
  const dispatch = useDispatch();
  const onSubmit = (data: ResetPasswordData) => {
    const { newPassword }: any = data;
    const { token } : any = parsedUrl.queryParams;
    const resetPasswordData = {
      newPassword,
      token,
    }
    dispatch(resetPassword(resetPasswordData));
    setSuccess(true);
  };

  return (
    <div className="SET_NEW_PASSWORD--">
      <div className="innerBox" id="loginInnerBox">
        <img className="logo" src={logo} alt="" />
        {success ? (
          <h4 className="success">Password changed successfully</h4>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="new-password-div">
              <label>Enter a new Password</label>
              <input 
                name="newPassword"
                placeholder="Password" 
                type="password" 
                ref={register({
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters"
                }
                })}
              />
              {errors.newPassword && (
                <div className="input-validation-error">{errors.newPassword.message}</div>
              )}
            </div>
            <div className="confirm-password-div">
              <input 
                name="reTypedNewPassword" 
                placeholder="Confirm Password" 
                type="password" 
                ref={register({
                  validate: value =>
                  value === password.current || "The passwords do not match"
                })}
              />
              {errors.reTypedNewPassword && (
                <div className="input-validation-error">{errors.reTypedNewPassword.message}</div>
              )}
            </div>
            <button className="primary-button resetButton" type="submit">
              Reset password
              {false ? <ButtonLoader /> : ''}
            </button>
          </form>
        )}
      </div>
    </div>
  )
}