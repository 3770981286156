import { Sidebar } from '../Sidebar';
import {NarrowSidebar} from '../NarrowSidebar';
import { MeetView } from '../MeetView';
import './index.scss';
import { useSelector } from 'react-redux';
import { selectIsSidebarOpen } from '../selectors';
import logo from 'images/branding/logo.png'
import React from 'react';
import {selectAppStatus, selectRoomData} from '../../app/selectors';
import { RESOURCES_BUCKET_URL } from '../../../configs';

export function ConferenceView() {
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const roomData = useSelector(selectRoomData);
  return (
    <div className="CONFERENCE_VIEW--">
      <div className="conference-div"><MeetView/></div>
      <a href="/" target="blank" className="brandingLogo">
        <img src={`${RESOURCES_BUCKET_URL}/${roomData?.drugImageKey}`} alt="" />
      </a>
      {isSidebarOpen ? (
        <div className="wide-sidebar-wrap">
          <Sidebar />
        </div>
      ) : (
        <div className="narrow-sidebar-wrap">
          <NarrowSidebar />
        </div>
      )}
    </div>
  );
}