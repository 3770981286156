import { Middleware } from "redux";
import { RootState } from "../../store/store";
import { socket } from "./socketConnection";
import { conferenceActions } from "../conference/slice";
import { authActions } from "../auth/slice";
import {
  joinedToConference,
  joinAsModerator,
  renameParticipant,
  renameParticipantByModerator,
} from "../conference/actions";
import {joinAsGuestRequest } from '../app/actions'

export const socketMiddleware: Middleware<{}, RootState> = (store) => (
  next
) => (action) => {
  switch (action.type) {
    case conferenceActions.joinAttempt.type:
      console.log("emit/join-attempt");
      socket.emit("join-attempt", action.payload);
      next(action);
      break;
    case joinAsModerator.type:
      console.log("emit/join-moderator");
      socket.emit("join-moderator", {
        room: store.getState().conference.roomWaitingFor,
        user: {
          name: store.getState().auth.currentUser.name,
          socketId: socket.id,
          uuid: store.getState().auth.currentUser.uuid,
          roles: store.getState().auth.currentUser.roles,
          status:  store.getState().auth.currentUser.status
        },
        reJoin: false,
      });
      next(action);
      break;
    case joinAsGuestRequest.type:
      console.log("emit/join-as-guest-request");
      socket.emit("join-as-guest-request", {
        room: store.getState().conference.roomWaitingFor,
        user: {
          uuid: store.getState().auth.currentUser.uuid,
          socketId: socket.id,
          name: store.getState().auth.currentUser.name,
          roles: store.getState().auth.currentUser.roles,
          status:  {...store.getState().auth.currentUser.status,audioMuted:store.getState().auth.settings.muteParticipantUponEntry || store.getState().auth.currentUser.status.audioMuted}
        },
        reJoin: false,
      });
      next(action);
      break;
    case conferenceActions.approveGuestJoinRequest.type:
      console.log("emit/join-guest");
      socket.emit("join-guest", {
        room: store.getState().conference.roomJoinedTo,
        user: action.payload,
        reJoin: false,
      });
      next(action);
      break;
    case conferenceActions.cancelGuestJoinRequest.type:
      console.log("emit/cancel-guest-join-request");
      socket.emit("cancel-guest-join-request", {
        room: store.getState().conference.roomJoinedTo,
        user: action.payload,
      });
      next(action);
      break;
    case joinedToConference.type:
      if (store.getState().auth.currentUser.roles.includes('moderator')) {
        console.log("emit/moderator-joined");
        socket.emit("moderator-joined", {
          room: store.getState().conference.roomJoinedTo,
          jitsiId: action.payload,
          uuid: store.getState().auth.currentUser.uuid
        });
      } else {
        console.log("emit/guest-joinedr");
        socket.emit("guest-joined", {
          room: store.getState().conference.roomJoinedTo,
          jitsiId: action.payload,
          uuid: store.getState().auth.currentUser.uuid
        });
      }
      next(action);
      break;
    case conferenceActions.participantJoined.type:
      console.log("emit/participant-joined");
      socket.emit("participant-joined", {
        room: store.getState().conference.roomJoinedTo,
        jitsiId: action.payload.id,
        name:action.payload.name,
      });
      next(action);
      break;  
    case conferenceActions.moderatorRequestAudioVideoToggleParticipant.type:
      console.log("emit/request-moderator-audio-video-toggle-on-participant");
      socket.emit("request-moderator-audio-video-toggle-on-participant", {
        room: store.getState().conference.roomJoinedTo,
        uuid: action.payload.uuid,
        mute:action.payload.mute,
        type:action.payload.type,
      });
      next(action);
      break; 
    case conferenceActions.audioVideoMuteStatusChanged.type:
      console.log("emit/audio-video-mute-status-changed");
      socket.emit("audio-video-mute-status-changed", {
        room: store.getState().conference.roomJoinedTo,
        uuid: store.getState().auth.currentUser.uuid,
        muted:action.payload.muted,
        type:action.payload.type,
      });
      next(action);
      break;   
    case conferenceActions.toggleMuteAll.type:
      console.log('room:' + store.getState().conference.roomJoinedTo);
      socket.emit("toggle-mute-all", {
        room: store.getState().conference.roomJoinedTo,
        muteAll:action.payload.muteAll,
      });
      next(action);
      break;
    case conferenceActions.makePresenter.type:
      console.log("emit/make-presenter");
      socket.emit("make-presenter", {
        room: store.getState().conference.roomJoinedTo,
        uuid:action.payload.uuid,
        jitsiId: action.payload.jitsiId,
        enable: action.payload.enable,
      });
      next(action);
      break;  
    case conferenceActions.askToUnmute.type:
      console.log("emit/ask-to-unmute");
      socket.emit("ask-to-unmute", {
        room: store.getState().conference.roomJoinedTo,
        uuid: action.payload.uuid,
        type: action.payload.type,
        by: action.payload.by,
      });
      next(action);
    break;   
    case authActions.updateSecuritySettings.type:
      console.log("emit/update-security-settings");
      socket.emit("update-security-settings", {
        room: store.getState().conference.roomJoinedTo,
        field: action.payload.field,
        value: action.payload.value,
      });
      next(action);
    break; 
    case conferenceActions.lowerAllHand.type:
      console.log("emit/lower-all-hand");
      socket.emit("lower-all-hand", {
        room: store.getState().conference.roomJoinedTo,
      });
      next(action);
    break;
    case conferenceActions.removeParticipantByModerator.type:
      console.log("emit/remove-participant-by-moderator");
      socket.emit("remove-participant-by-moderator", {
        room: store.getState().conference.roomJoinedTo,
        uuid:action.payload.uuid
      });
      next(action);
    break; 
    case conferenceActions.closeMeeting.type:
      console.log("emit/close-meeting-by-moderator");
      socket.emit("close-meeting-by-moderator", {
        room: store.getState().conference.roomJoinedTo,
      });
      next(action);
    break; 
    case conferenceActions.lowerHandByModerator.type:
      console.log("emit/lower-hand-by-moderator");
      socket.emit("lower-hand-by-moderator", {
        room: store.getState().conference.roomJoinedTo,
        jitsiId: action.payload.jitsiId,
        uuid: action.payload.uuid,
      });
      next(action);
    break;                           
    case renameParticipant.type:
      console.log("emit/rename-participant");
      socket.emit("rename-participant", {
        room: store.getState().conference.roomJoinedTo,
        newName: action.payload.newName,
        uuid: action.payload.uuid,
      });
      next(action);
    break;
    case conferenceActions.participantBackToWaitingRoom.type:
      socket.emit("participant-backTo-waitingRoom", {
        room: store.getState().conference.roomJoinedTo,
        uuid: action.payload.uuid,
      });
      next(action);
    break;
    case renameParticipantByModerator.type:
      console.log("emit/rename-participant-by-moderator");
      socket.emit("rename-participant-by-moderator", {
        room: store.getState().conference.roomJoinedTo,
        newName: action.payload.newName,
        uuid: action.payload.uuid,
      });
      next(action);
    break;                           
    default:
      next(action);
      break;
  }
};
