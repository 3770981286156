import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import logo from 'images/branding/logo.png';
import {ButtonLoader} from 'features/buttonLoader';
import {selectIsLoading, selectIsAuthenticated, selectIsErrorMessage} from '../selectors';
import { selectIsAllowedToJoinRoom } from '../../conference/selectors';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../slice';
import { useHistory } from 'react-router-dom';
import { joinAsModerator } from '../../conference/actions';
import { Link } from 'react-router-dom';
import { UrlParser } from 'url-params-parser';


type LoginData = {
  email: string;
  password: string;
  room:string;
};

export function Login() {
  const [boxPlacement, setBoxPlacement] = useState('');
  const { register, handleSubmit, errors } = useForm<LoginData>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [room, setRoom] = useState('');

  const isLoading = useSelector(selectIsLoading);
  const errorMessage = useSelector(selectIsErrorMessage);
  const isAllowedToJoinRoom = useSelector(selectIsAllowedToJoinRoom);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  
  const onSubmit = (data: LoginData) => {
    dispatch(login({...data,room}));
  }

  useEffect(() => {
    function handleResize() {
      const boxHeight = document.querySelector("#loginInnerBox");
      if(boxHeight && window.innerHeight > boxHeight.clientHeight){
        setBoxPlacement('center');
      } else {
        setBoxPlacement('scroll');
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    const parsedUrl = UrlParser(window.location.href, '/:room');
    if(parsedUrl.namedParams.room !== '/'){
      setRoom(parsedUrl.namedParams.room);
    } 
    
    return () => window.removeEventListener("resize", handleResize);
  },[])
    

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(joinAsModerator());
    }
  }, [dispatch, history, isAuthenticated]);

  useEffect(() => {
    if (isAllowedToJoinRoom) {
      history.push('/conference');
    }
  }, [history, isAllowedToJoinRoom]);

  return (
    <div className={`LOGIN-- ${boxPlacement}`}>
      <div className="innerBox" id="loginInnerBox">
        <img className="logo" src={logo} alt="" />
        <h2>Sign In</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
        {errorMessage && <h4 className="errorMessage">{errorMessage}</h4>}
          <div className="nameInputDiv">
            <label>Email Address</label>
            <input name="email" type="email" ref={register({ required: true })} required />
            {errors.email?.type === 'required' && (
              <div className="input-validation-error">Email is required</div>
            )}
          </div>
          <div className="nameInputDiv">
            <label>Password</label>
            <input name="password" ref={register({ required: true })} type="password" required />
            {errors.email?.type === 'required' && (
              <div className="input-validation-error">Password is required</div>
            )}
          </div>
          {/* <div className="forgotPasswordDiv">
            <Link to="/forgot-password">Forgot password</Link>
          </div> */}
          <button className="primary-button signinButton" type="submit">
            Sign In
            {isLoading ? <ButtonLoader /> : ''}
          </button>
        </form>
        {/* <div className="dontHaveAccount">
          <span>Don&apos;t have an account?</span>
          <Link to="/signup">&nbsp;&nbsp;Sign up</Link>
        </div> */}
      </div>
    </div>
  )
}