import React from 'react';
import logo from 'images/branding/logo.png';
import { ButtonLoader } from 'features/buttonLoader';
import './index.scss';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { sendForgotPasswordToken } from '../slice';

type ForgotPasswordData = {
  email: string;
  // password: string;
};

export function ForgotPassword() {
  const { register, handleSubmit } = useForm<ForgotPasswordData>();
  // const forgotPassword = useSelector(selectForgotPassword);
  const dispatch = useDispatch();
  const onSubmit = (data: ForgotPasswordData) => {
    dispatch(sendForgotPasswordToken(data));
    alert('We have sent an email with a confirmation link to your email address');
  };
  return (
    <div className="FORGOT_PASSWORD--">
      <div className="innerBox" id="loginInnerBox">
        <img className="logo" src={logo} alt="" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Enter your registered Email Address</label>
          <input 
            name="email" 
            type="text" 
            placeholder="Email address"
            ref={register({
              pattern: RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
              required: true,
            })}
          />
          {false && (
            <div className="input-validation-error">Email is required</div>
          )}
          <button className="primary-button sendLinkButton" type="submit">
            Send a Link
            {false ? <ButtonLoader /> : ''}
          </button>
        </form>
      </div>
    </div>
  )
}
