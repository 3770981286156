import './index.scss';
import { EachWaiting } from '../EachWaiting';
import { selectGuestRequests } from '../selectors';
import { useSelector, useDispatch } from 'react-redux';
import {conferenceActions} from '../slice'
import React from 'react';

export function WaitingList() {
  const dispatch = useDispatch();
  const guestRequests = useSelector(selectGuestRequests);

  const handleAdmitAllButtonClick = () => {
    guestRequests.forEach(request => {
      dispatch(conferenceActions.approveGuestJoinRequest(request))
    })
  };

  return (
    <div className="WAITING_LIST--">
      {guestRequests.length >0 && 
      <div className="waitingTitle">
        <h4>{`Waiting in Lobby (${guestRequests.length})`}</h4>
        <button className="primary-button admitAll" onClick={handleAdmitAllButtonClick}>Admit all</button>
      </div>
      }
      <div className="waitingList">
        {
          guestRequests.map(request => <EachWaiting guest={request} />)
        }
      </div>
    </div>
  );
}