import React, { useEffect, useState } from 'react';
import bye from 'images/conference/bye.svg';
import './index.scss'

export function ThankYouPage() {
    return (
    <div className="THANK_YOU_PAGE--">
        <div className="inner">
            <img src={bye} alt="lock" />
            <div className="rightSide">
                <h1>Thank you!</h1>
                <div>See you back soon.</div>
            </div>
        </div>
    </div>
    );
  }