import './index.scss';
import { useDispatch } from 'react-redux';
import {conferenceActions} from '../slice'
import user from 'images/conference/user.svg';
import React from 'react';

export function EachWaiting({ guest }: any) {
  const dispatch = useDispatch();
  return (
    <div className="EACH_WAITING--">
      <div className="leftHandSide">
        <img className="profile" src={user} alt="" />
        <span>{guest.name}</span>
      </div>
      <div className="rightHandSide">
        <button onClick={() => dispatch(conferenceActions.approveGuestJoinRequest(guest))} className="primary-button admitButton">Admit</button>
        <button onClick={() => dispatch(conferenceActions.cancelGuestJoinRequest(guest))} className="secondary-button admitButton">Remove</button>
      </div>
    </div>
  );
}